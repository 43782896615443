import { Resellerconstants } from "../constanta/ResellerContant";

const initialState = {
  itemsResseler: [],
  loading: false,
  invoice: [],
};

export default function partnerReseller(state = initialState, actions) {
  switch (actions.type) {
    case Resellerconstants.RESELLER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Resellerconstants.RESELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsResseler: actions.reseller,
      };
    case Resellerconstants.RESELLER_FAILURE:
      return {
        ...state,
        itemsResseler: actions.error,
      };
    case Resellerconstants.INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Resellerconstants.INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: actions.invoice,
      };
    case Resellerconstants.INVOICE_FAILURE:
      return {
        ...state,
        invoice: actions.error,
      };
    default:
      return state;
  }
}
