

import React from "react";
import { Button } from "reactstrap";
import { PanelToc } from "../../../shared/components/Panel";

const rafiki = `${process.env.PUBLIC_URL}/img/rafiki.svg`;
const toc = `${process.env.REACT_APP_BASE_URL}TOC.pdf`;

// eslint-disable-next-line react/prefer-stateless-function
class AffiliateToc extends React.Component {
  render() {
    return (
      <>
        <PanelToc xs={12} md={12} lg={12}>
          <div className="banner__welcome">
            <img className="flat_toc" src={rafiki} alt="" />
            <div className="banner__text">
              <h2 className="banner__title-download">Hi! we provide tutorial you can give to client you refer to us</h2>
              <p className="text__detail">to show them the right way to set schedule with our manager in order to make deal with us</p>
              {/* <p className="text__detail_you">download here</p> */}
              <div className="flex_button">
                <a target="_blank" rel="noreferrer" href={toc}>
                  <Button type="button" color="banner">Download here</Button>
                </a>
              </div>
            </div>
          </div>
        </PanelToc>
      </>
    );
  }
}

export default AffiliateToc;