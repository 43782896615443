import React from "react";
import MenuContent from "./MenuContent";

function ProfileMenu() {
  return (
    <div className="profile__menu__scroll scroll">
      <div className="sidebar__wrapper profile__wrapper--desktop">
        <MenuContent />
      </div>
    </div>
  );
}

export default ProfileMenu;
