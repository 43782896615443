import React from "react";
import ReactGA from "react-ga";

const GOOGLE_ANALYTIC = "UA-216363468-1";

ReactGA.initialize(GOOGLE_ANALYTIC);

function withPageView(WrappedComponent, options = {}) {
  const TrackPageView = (page) => {
    ReactGA.set({
      ...page,
      options,
    });
    ReactGA.pageview(page);
  };
  return class extends React.Component {
    state = {
      page: null,
    };

    static getDeliveredStateFromProps(state, props) {
      const page = props.location.pathname + props.location.search;
      if (page !== state.page) {
        return {
          page,
        };
      }
      return null;
    }

    componentDidMount() {
      const page = this.props.location.pathname;
      TrackPageView(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname;
      const nextPage = this.state.page;
      if (currentPage !== currentPage) {
        TrackPageView(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withPageView;
