

import React from "react";
import ResetPassword from "../components/ResetPassword";

// eslint-disable-next-line react/prefer-stateless-function
function ResetPasswordPage(props) {
  const {
    location: { state: locationState },
  } = props;
  const redirect = locationState ? locationState.redirectFrom : null;
  let redirectFrom = null;
  if (redirect && redirect.pathname) {
    const { search, pathname } = redirect;
    redirectFrom = `${pathname}${search || ''}`;
  }
  return (
    <div className="">
      <div className="account__wrapper-position">
        <div className="account__wrapper-card" style={{ display: "flex", justifyContent: "center" }}>
          <div className="account__wrapper">
            <div className="account__card account__margin">
              <div className="account__head">
                <h3 className="account__title">Reset Your Password</h3>
              </div>
              <ResetPassword {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
