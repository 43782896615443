import React from "react";
import { connect } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import { Panel } from "../../../shared/components";
import { AddPayment } from "./AddPayment";
import { PaymentList } from "./ListPayment";

// eslint-disable-next-line react/prefer-stateless-function
class PaymentView extends React.Component {
  state = {
    toggle: false,
    payPal: false,
    bankPayment: false,
    isLoading: this.props.loading,
    isLoaded: this.props.isLoaded,
  };

  componentDidMount() {
    window.addEventListener("load", this.onLoaded);
  }

  handleDropdown = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  };

  handleMasterCard = () => {
    this.setState((prevState) => ({
      bankPayment: !prevState.bankPayment,
      payPal: false,
    }));
  };

  handlePayPal = () => {
    this.setState((prevState) => ({
      payPal: !prevState.payPal,
      bankPayment: false,
    }));
  };

  handleClearState = () => {
    this.setState((prevState) => ({
      payPal: false,
      bankPayment: false,
    }));
  };

  render() {
    const { open, toggle, bankPayment, payPal } = this.state;
    return (
      <Panel
        xs={12}
        md={12}
        lg={12}
        title="Payout Method"
        tooltip={
          <FontAwesomeIcon
            id="payment"
            style={{ marginLeft: "8px" }}
            icon={faQuestionCircle}
          />
        }
      >
        <>
          <PaymentList />
          {this.props.loading ? (
            <div
              style={{
                marginTop: "12px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <AddPayment
                bankPayment={bankPayment}
                payPal={payPal}
                setNull={this.setNull}
                handlePayPal={this.handlePayPal}
                handleMasterCard={this.handleMasterCard}
                handleDropdown={this.handleDropdown}
                handleClearState={this.handleClearState}
                toggle={toggle}
              />
            </>
          )}
        </>
        <UncontrolledTooltip placement="right" target="payment">
          This is a list of payments used by the blankontech partnership You can
          make PayPal and bank payments
        </UncontrolledTooltip>
      </Panel>
    );
  }
}

function mapState(state) {
  const { items, loading } = state.paymentMethod;
  return { items, loading };
}

const PagePayment = connect(mapState)(PaymentView);

export { PagePayment as PaymentView };
