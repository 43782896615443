import { AffiliateConstants } from "../constanta/AffiliateContant";
import authFetch from "../helpers/authFetch";
import { authHeader } from "../helpers/authHeader";
import { Affiliate } from "../utils/affiliateUtils";

function affiliatesList() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return (dispatch) => {
    dispatch(request());
    return authFetch(`partner/affiliates`, requestOptions)
      .then((affiliate) => {
        dispatch(success(affiliate));
      });
  };
  function request() { return { type: AffiliateConstants.AFFILIATE_REQUEST }; }
  function success(affiliate) { return { type: AffiliateConstants.AFFILIATE_SUCCESS, affiliate }; }
}

function getAffiliatesDashboard() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return (dispatch) => {
    dispatch(request());
    return authFetch(`partner/dashboard`, requestOptions)
      .then((affiliateDashboard) => {
        dispatch(success(affiliateDashboard));
      });
  };
  function request() { return { type: AffiliateConstants.AFFILIATE_DASHBOARD_REQUEST }; }
  function success(affiliateDashboard) { return { type: AffiliateConstants.AFFILIATE_DASHBOARD_SUCCESS, affiliateDashboard }; }
}

function affiliatesProject() {
  return (dispatch) => {
    dispatch(request());
    Affiliate.AffiliateProject()
      .then(
        (project) => {
          const { project: { results } } = project;
          const listAffiliate = results.map((list, index) => {
            return {
              ...results[index],
            };
          });
          const projects = Promise.all(listAffiliate);
          dispatch(success(projects));
        },
        (error) => dispatch(failure(error)),
      );
  };
  function request() { return { type: AffiliateConstants.PROJECT_REQUEST }; }
  function success(project) { return { type: AffiliateConstants.PROJECT_REQUEST, project }; }
  function failure(error) { return { type: AffiliateConstants.PROJECT_FAILURE, error }; }
}

function affiliatesPayout() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return (dispatch) => {
    dispatch(request());
    return authFetch(`partner/payout`, requestOptions)
      .then((payout) => {
        dispatch(success(payout));
      });
  };
  function request() { return { type: AffiliateConstants.PAYOUT_REQUEST }; }
  function success(payout) { return { type: AffiliateConstants.PAYOUT_SUCCESS, payout }; }
}

export const AffiliateActions = {
  affiliatesList,
  affiliatesProject,
  affiliatesPayout,
  getAffiliatesDashboard,
};