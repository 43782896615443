import { Resellerconstants } from "../constanta/ResellerContant";
import authFetch from "../helpers/authFetch";
import { authHeader } from "../helpers/authHeader";

function resellerList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return (dispatch) => {
        dispatch(request());
        return authFetch(`partner/projects`, requestOptions)
        .then((reseller) => {
          dispatch(success(reseller));
        });
      };
    function request() { return { type: Resellerconstants.RESELLER_REQUEST }; }
    function success(reseller) { return { type: Resellerconstants.RESELLER_SUCCESS, reseller }; }
}

function invoiceList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return (dispatch) => {
        dispatch(request());
        return authFetch(`partner/invoice`, requestOptions)
        .then((invoice) => {
          dispatch(success(invoice));
        });
      };
    function request() { return { type: Resellerconstants.INVOICE_REQUEST }; }
    function success(invoice) { return { type: Resellerconstants.INVOICE_SUCCESS, invoice }; }
}

export const resellerActions = {
    resellerList,
    invoiceList,
};