import React from "react";
import PlusIcon from "mdi-react/PlusIcon";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { paymentActions } from "../../../redux/actions/paymentActions";
import ModalFormPayment from "./ModalFormPayment";
import ModalFormBank from "./ModalFormBank";
import ModalFormPayPal from "./ModalFormPaypal";
import { maxLengthCheck, Slice } from "../../../shared/helpers";

// import { ModalSuccess, ModalWarning } from "../../../shared/components/ModalAllert";

// eslint-disable-next-line react/prefer-stateless-function
class AddPayment extends React.Component {
  state = {
    paypal: { email: "" },
    selectedValue: "USD",
    bankPayments: {
      bank_currency: "",
      routing_number: "",
      account_number: "",
      bank_name: "",
      bic: "",
      iban: "",
      name: "",
    },
    currency: [
      {
        name: "USD",
        value: "USD",
      },
      {
        name: "EURO",
        value: "EURO",
      },
    ],
    type: {
      bank: 1,
      paypal: 2,
    },
    usd: "usd",
    euro: "euro",
    submitted: {
      bank: false,
      paypal: false,
    },
    submitted2: false,
    ErrorMessage: {},
    paymentState: {},
    modal_success: {
      message: null,
      toggle: false,
    },
    modal_warning: {
      message: null,
      toggle: false,
    },
    open: false,
  };

  selectedValueHandler = (e) => {
    this.setState({ selectedValue: e.target.value, ErrorMessage: {} });
  };

  handleModal = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
    this.props.handleClearState();
    this.clearFormState();
  };

  handleShowModalSuccess = (message) => {
    this.setState({
      modal_success: {
        message: message,
        toggle: true,
      },
    });
  };

  handleCloseModalWarning = () => {
    this.setState({
      modal_warning: {
        message: null,
        toggle: false,
      },
    });
  };

  clearErrorState = () => {
    this.setState({ ErrorMessage: {}, error: {}, deleteError: null });
  };
  clearFormState = () => {
    this.setState({
      paypal: { email: "" },
      selectedValue: "USD",
      bankPayments: {
        bank_currency: "",
        routing_number: "",
        account_number: "",
        bank_name: "",
        bic: "",
        iban: "",
        name: "",
      },
      submitted: {
        bank: false,
        paypal: false,
      },
      ErrorMessage: {},
      error: null,
    });
  };
  clearSubmitState = () => {
    this.setState({
      open: false,
    });
    this.clearFormState();
  };

  showModalWarning = (message) => {
    this.setState({
      modal_warning: {
        message: message,
        toggle: true,
      },
      deleteError: null,
    });
  };

  handleInlineError = (messageKey, errorKey, errorResponse = {}) => {
    if (errorResponse && "json" in errorResponse) {
      let errorMessage = errorResponse.json[messageKey];

      if (messageKey in errorResponse.json) {
        this.setState({
          ErrorMessage: { [errorKey]: errorMessage },
        });
      } else {
        this.clearSubmitState();
        this.clearErrorState();
        this.showModalWarning(
          "OOPS! Something went wrong. Please try again or contact customer support"
        );
      }
    }
  };

  handleSubmitBank = async (event) => {
    event.preventDefault();
    const { bankPayments, type, usd, euro, selectedValue } = this.state;
    this.setState({
      submitted: {
        bank: true,
        paypal: false,
      },
    });
    bankPayments.bank_currency = selectedValue;
    let bankValues = {};
    let is_bank_input_valid = false;
    if (
      selectedValue === "USD" &&
      bankPayments.routing_number &&
      bankPayments.bank_name &&
      bankPayments.account_number &&
      bankPayments.name
    ) {
      const banksUsd = Slice(bankPayments, [
        "bank_name",
        "account_number",
        "name",
        "routing_number",
        "bank_currency",
      ]);
      bankValues = { ...banksUsd, usd };
      is_bank_input_valid = true;
    } else if (
      selectedValue === "EURO" &&
      bankPayments.bic &&
      bankPayments.bank_name &&
      bankPayments.iban &&
      bankPayments.name
    ) {
      const banksEuro = Slice(bankPayments, [
        "bank_name",
        "iban",
        "name",
        "bic",
        "bank_currency",
      ]);
      bankValues = { ...banksEuro, euro };
      is_bank_input_valid = true;
    }
    if (!is_bank_input_valid) {
      return;
    }
    await this.props.createPayment(bankValues, type.bank);

    let error = this.props.error;

    if (error) {
      this.handleInlineError("data", "bank", error);
    } else {
      this.clearSubmitState();
      this.handleShowModalSuccess("Bank account added successfully");
    }
  };

  handleChangeSubmit = async (event) => {
    event.preventDefault();
    const { paypal, type } = this.state;
    this.setState({
      submitted: {
        bank: false,
        paypal: true,
      },
    });
    if (!this.isValidEmail()) {
      this.setState({
        ErrorMessage: {
          email: "This is not a valid email",
        },
      });
      return;
    }
    if (paypal.email) {
      await this.props.createPayment(paypal, type.paypal);
      let error = this.props.error;
      if (error) {
        this.handleInlineError("email", "check_email", error);
      } else {
        this.clearSubmitState();
        this.handleShowModalSuccess("Paypal account added successfully");
      }
    }
  };

  isValidEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.paypal.email).toLowerCase());
  }

  render() {
    const {
      handleDropdown,
      toggle,
      handleMasterCard,
      handlePayPal,
      handleClearState,
      bankPayment,
      payPal,
      items,
    } = this.props;
    const {
      paypal: { email },
      bankPayments: {
        bic,
        routing_number,
        bank_name,
        account_number,
        iban,
        name,
      },
      currency,
      open,
    } = this.state;
    return (
      <>
        <div>
          <Button className="icon" onClick={this.handleModal} color="payment">
            <PlusIcon />
            Add Payout
          </Button>
          <ModalFormPayment
            toggle={open}
            handleModal={this.handleModal}
            addPayment
            onSubmit={
              bankPayment ? this.handleSubmitBank : this.handleChangeSubmit
            }
            handleMasterCard={handleMasterCard}
            handlePayPal={handlePayPal}
            handleDropdown={handleDropdown}
            dropdown={toggle}
            handleClearState={handleClearState}
          >
            {bankPayment && (
              <>
                <div className="form__form-group">
                  <span className="form__form-group-label">Currency</span>
                  <div className="form__form-group-select">
                    <div className="form__form-group-input-wrap">
                      <select
                        id="searchSelect"
                        name="searchSelect"
                        onChange={this.selectedValueHandler}
                        className="form__select"
                      >
                        {currency.map((item) => (
                          <option key={item.value}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <ModalFormBank
                  submitted={this.state.submitted.bank}
                  selectedValue={this.state.selectedValue}
                  errors={
                    this.state.ErrorMessage.bank && (
                      <div
                        id="validationServer03Feedback"
                        className="form__form-group-error"
                      >
                        {this.state.ErrorMessage.bank}
                      </div>
                    )
                  }
                  errorsEuro={
                    this.state.ErrorMessage.bankEuro && (
                      <div
                        id="validationServer03Feedback"
                        className="form__form-group-error"
                      >
                        {this.state.ErrorMessage.bankEuro}
                      </div>
                    )
                  }
                  maxLength={maxLengthCheck}
                  onChange={(bank) => this.setState(bank)}
                  bankPayments={{
                    bank_name,
                    name,
                    iban,
                    bic,
                    routing_number,
                    account_number,
                  }}
                />
                <button
                  className="btn btn-account account__btn account__btn--small"
                  style={{
                    marginTop: "10px",
                    color: "#fff",
                    width: "100%",
                    position: "relative",
                  }}
                  type="submit"
                >
                  Save
                </button>
              </>
            )}
            {payPal && (
              <>
                <ModalFormPayPal
                  submitted={this.state.submitted.paypal}
                  paypal={{ email }}
                  onChange={(paypal) => this.setState(paypal)}
                />
                {this.state.ErrorMessage.check_email && (
                  <div
                    id="validationServer03Feedback"
                    className="form__form-group-error"
                  >
                    {this.state.ErrorMessage.check_email}
                  </div>
                )}
                <button
                  className="btn btn-account account__btn account__btn--small"
                  style={{
                    marginTop: "10px",
                    color: "#fff",
                    width: "100%",
                    position: "relative",
                  }}
                  type="submit"
                >
                  Save
                </button>
              </>
            )}
          </ModalFormPayment>
          {/* <ModalSuccess
            toggle={this.state.modal_success.toggle}
            message={this.state.modal_success.message}
            closeModal={() => this.handleCloseModalSuccess()}
          ></ModalSuccess>
          <ModalWarning
            toggle={this.state.modal_warning.toggle}
            message={this.state.modal_warning.message}
            closeModal={() => this.handleCloseModalWarning()}
          ></ModalWarning> */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { error, loading, items } = state.paymentMethod;
  // const { bankPayment, payPal } = state;
  return { error, loading, items };
}

const actionCreators = {
  createPayment: paymentActions.createPaymentMethod,
};

const connectedAddPayment = connect(
  mapStateToProps,
  actionCreators
)(AddPayment);
export { connectedAddPayment as AddPayment };
