

import React, { useState } from "react";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import HistoryCard from "./HistoryCard";
import ModalTransaction from "./ModalTrasaction";

// eslint-disable-next-line react/prefer-stateless-function
function TransactionAccept(props) {
  const [activeTab, setActiveTab] = useState("1");
  const [modal, setModal] = useState(0);

  const getModal = (id) => {
    setModal(id);
  };

  const getModalClose = (id) => {
    setModal(0);
  };

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card className="border_radius card-historys">
        <div className="profile__card tabs tabs--bordered-bottom">
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => handleToggle("1")}
                >
                  Payout History
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent style={{ overflowY: "auto" }} activeTab={activeTab}>
              {props.bodyHistory.length === 0 ? (
                <div style={{ marginTop: "24px" }}>
                  <h3 style={{ justifyContent: "center", display: "flex" }}><FontAwesomeIcon icon={faHourglass} /></h3>
                  <p style={{ justifyContent: "center", display: "flex", marginTop: "12px" }}>You don&apos;t have any history</p>
                </div>
              ) : (
                props.bodyHistory && props.bodyHistory.map((item) => (
                  <div key={item.id}>
                    <HistoryCard
                      detail_icon={item.id}
                      date={new Date(item.created_at).toISOString().split('T')[0]}
                      getModal={() => getModal(item.id)}
                      amount={item.amount}
                      file={item.file}
                      detail_date={item.id}
                      download={item.id}
                    />
                    <ModalTransaction
                      handleModal={() => getModalClose(item.id)}
                      date={new Date(item.created_at).toISOString().split('T')[0]}
                      explanation={item.explanation}
                      file={item.file}
                      amount={item.amount}
                      toggle={modal === item.id}
                    />
                  </div>
                ))
              )}
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
}

// TransactionAccept.propTypes = {
//   bodyHistory: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number,
//       name: PropTypes.string,
//       file: PropTypes.node,
//       amount: PropTypes.number,
//     }),
//   ),
// };

TransactionAccept.defaultProps = {
  bodyHistory: [],
};

const AffiliateHistoryRedux = TransactionAccept;

export { AffiliateHistoryRedux as TransactionAccept };
