import React from "react";
import PropTypes from "prop-types";
import LanIcon from "mdi-react/LanIcon";
import ShareVariantIcon from "mdi-react/ShareVariantIcon";
import SidebarLink from "./SidebarLink";

function SidebarContent({ onClick }) {
  const handleHideSidebar = () => {
    onClick();
  };

  return (
    <>
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Affiliate"
            icon={<LanIcon size="18px" />}
            route={`${process.env.REACT_APP_BASE_URL}affiliate`}
            onClick={handleHideSidebar}
          />
          <SidebarLink
            title="Reseller"
            icon={<ShareVariantIcon size="18px" />}
            route={`${process.env.REACT_APP_BASE_URL}reseller`}
            onClick={handleHideSidebar}
          />
        </ul>
      </div>
    </>
  );
}

// SidebarContent.propTypes = {
//   onClick: PropTypes.func.isRequired,
// };

export default SidebarContent;
