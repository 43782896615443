import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const ProfileLink = ({
  title, route,
}) => (
  <NavLink
    to={route}
    activeClassName="profile__link-active"
  >
    <div className="profile__link">
      <p className="sidebar__link-title">
        {title}
      </p>
    </div>
  </NavLink>
);

ProfileLink.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string,
};

ProfileLink.defaultProps = {
  route: '/',
};

export default ProfileLink;
