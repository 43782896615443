import React, { Fragment } from "react";
import {
  Route, Switch, withRouter, Redirect,
} from "react-router-dom";
import MainWrapper from "./MainWrapper";
import { LoginPage, RegisterPage, ResetPasswordPage } from "../Auth";
import ForgetPasswordPage from "../Auth/views/ForgetPasswordPage";
import Pages from "./Pages";
import { Tos } from "../Layout/TOS";

// eslint-disable-next-line react/prefer-stateless-function
class Routers extends React.Component {

  render() {
    return (
      <MainWrapper>
        <Switch>

          <Route
            path={`${process.env.REACT_APP_BASE_URL}login`}
            exact
            render={(props) => <LoginPage {...props} />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}register`}
            exact
            render={(props) => <RegisterPage {...props} />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}terms-condition`}
            exact
            render={(props) => <Tos {...props} />}
          />
          <Route exact path={`${process.env.REACT_APP_BASE_URL}forget-password`} component={ForgetPasswordPage} />
          <Route exact path={`${process.env.REACT_APP_BASE_URL}password-reset`} component={ResetPasswordPage} />
          <Redirect exact from={`${process.env.REACT_APP_BASE_URL}`} to={`${process.env.REACT_APP_BASE_URL}affiliate`} />
          <Pages />

        </Switch>
      </MainWrapper>
    );
  }
}

const RoutersComponent = withRouter(Routers);

export { RoutersComponent as Routers };
