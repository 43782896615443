import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";

function Panel({
  xs, sm, md, lg, xl, children, title, header, tooltip,
}) {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardBody className="panel__body">
          <div style={{ paddingBottom: "8px" }} className={header ? `panel__title design_tooltip nav-tabs` : "design_tooltip"}>
            <h5 className="bold-text">{title}</h5>
            {tooltip}
          </div>
          <div className="panel__content">{children}</div>
        </CardBody>
      </Card>
    </Col>
  );
}

export function PanelCommision({
  xs, sm, md, lg, xl, children, title, header,
}) {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardBody className="panel__body card-body-commision">
          <div className={header ? `panel__title` : ""}><h5 className="bold-text_commision">{title}</h5></div>
          <div className="panel__content">{children}</div>
        </CardBody>
      </Card>
    </Col>
  );
}

export function PanelPayout({
  xs, sm, md, lg, xl, children, title, header,
}) {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardBody className="panel__body card-body-payout">
          <div className={header ? `panel__title` : ""}><h5 className="bold-text_commision">{title}</h5></div>
          <div className="panel__content">{children}</div>
        </CardBody>
      </Card>
    </Col>
  );
}

export function PanelCompare({
  xs, sm, md, lg, xl, children, title, header,
}) {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardBody className="panel__body card-body-compare">
          <div className={header ? `panel__title` : ""}><h5 className="bold-text_commision">{title}</h5></div>
          <div className="panel__content">{children}</div>
        </CardBody>
      </Card>
    </Col>
  );
}

export function PanelWelcome({
  xs, sm, md, lg, xl, children, title, header,
}) {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardBody className="panel__body card-body-welcome">
          <div className={header ? `panel__title` : ""}><h5 className="bold-text_commision">{title}</h5></div>
          <div className="panel__content">{children}</div>
        </CardBody>
      </Card>
    </Col>
  );
}

export function PanelToc({
  xs, sm, md, lg, xl, children, title, header,
}) {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardBody className="panel__body card-body-toc">
          <div className={header ? `panel__title` : ""}><h5 className="bold-text_commision">{title}</h5></div>
          <div className="panel__content">{children}</div>
        </CardBody>
      </Card>
    </Col>
  );
}

export function PanelProfileTos({
  xs, sm, md, lg, xl, children, title, header,
}) {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Card>
        <CardBody className="panel__body card-body-profile">
          <div className={header ? `panel__title` : ""}><h5 className="bold-text_commision">{title}</h5></div>
          <div className="panel__content">{children}</div>
        </CardBody>
      </Card>
    </Col>
  );
}

// Panel.propTypes = {
//   xs: PropTypes.number,
//   sm: PropTypes.number,
//   md: PropTypes.number,
//   lg: PropTypes.number,
//   tooltip: PropTypes.string.isRequired,
//   xl: PropTypes.number,
//   title: PropTypes.string,
//   children: PropTypes.string,
//   header: PropTypes.bool,
// };

Panel.defaultProps = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
  title: "",
  children: "",
  header: false,
};

export default Panel;

// export {
//   Panel,
//   // PanelCommision,
// };
