import React from "react";
import { Link } from "react-router-dom";
import { LayoutAuth } from "../../../shared/components/LayoutAuth";
import { RegisterForm } from "../components/RegisterForm";

const blankon = `${process.env.PUBLIC_URL}/img/favblannkon.webp`;

// eslint-disable-next-line react/prefer-stateless-function
class RegisterPage extends React.Component {
  render() {
    return (
      <LayoutAuth>
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">
                Register to partnership platform
              </h3>
              <img className="account__head-image" src={blankon} alt="" />
            </div>
            <RegisterForm />
          </div>
          <span className="footer__link">
            <Link className="footer__link-term" to={`${process.env.REACT_APP_BASE_URL}terms-condition`}>Terms and Condition</Link>
            <p>Copyright &copy; {new Date().getFullYear()}</p>
          </span>
        </div>
      </LayoutAuth>
    );
  }
}

export default RegisterPage;
