import React, { useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import LogoutIcon from "mdi-react/LogoutIcon";
import { connect } from "react-redux";
import AccountIcon from "mdi-react/AccountIcon";
import AccountCircleIcon from "mdi-react/AccountCircleIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { userActions } from "../../../redux/actions/authActions";

function TopbarProfile(props) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = () => {
    props.logout();
  };
  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
        <span className="topbar__avatar-img" style={{ paddingTop: "7px" }}><AccountCircleIcon /></span>
        {/* <p className="topbar__avatar-name">{props.user.firstName} {props.user.lastName}</p> */}
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="Profile"
            icon={<AccountIcon size="18px" />}
            click={handleToggleCollapse}
            path={`${process.env.REACT_APP_BASE_URL}overview`}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Log Out"
            icon={<LogoutIcon size="18px" />}
            path={`${process.env.REACT_APP_BASE_URL}login`}
            click={handleLogout}
          />
        </div>
      </Collapse>
    </div>
  );
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {
  logout: userActions.logout,
};

const TopBar = connect(mapState, actionCreators)(TopbarProfile);

export {
  TopBar as TopbarProfile,
};