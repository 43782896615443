import React from "react";
import PropTypes from "prop-types";
import Scrollbar from "react-smooth-scrollbar";
import classNames from "classnames";
import SidebarContent from "./SidebarContent";
import { SidebarProps } from "../../../shared/prop-types/ReducerProps";
import SidebarContentDetail from "./SidebarContentDetail";

function Sidebar({
  changeMobileSidebarVisibility,
  sidebar,
}) {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });

  return (
    <div className={sidebarClass}>
      <button
        type="button"
        aria-label="sidebar visibility"
        className="sidebar__back"
        onClick={changeMobileSidebarVisibility}
      />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            onClick={() => { }}
          />
          <SidebarContentDetail
            onClick={() => { }}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
          />
        </div>
      </Scrollbar>
    </div>
  );
}

// Sidebar.propTypes = {
//   sidebar: SidebarProps.isRequired,
//   changeMobileSidebarVisibility: PropTypes.func.isRequired,
// };

export default Sidebar;
