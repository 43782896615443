

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AlertComponent from "../../../shared/components/Message/Alert";
import { userActions } from "../../../redux/actions/authActions";

// type State = {
//   email,
//   ErrorMessage: any,
//   error: boolean,
//   success_message: any,
//   isLoading: boolean,
// };

// eslint-disable-next-line react/prefer-stateless-function
class ForgetPassword extends React.Component {
  state = {
    email: "",
    ErrorMessage: {},
    error: false,
    success_message: '',
    isLoading: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;
    this.setState({
      isLoading: true,
      ErrorMessage: {},
      success_message: '',
      error: false,
    });
    this.props
      .postForgetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          success_message: 'Reset password link has been sent to your email',
          error: false,
        });
      })
      .catch((error) => {
        const { json } = error;
        let ErrorMessage = "";
        if (!json) {
          ErrorMessage = "Something went wrong.";
        } else if (json.nonFieldErrors) {
          ErrorMessage = json.nonFieldErrors;
        } else {
          ErrorMessage = json;
        }
        this.setState({
          ErrorMessage: ErrorMessage,
          isLoading: false,
          error: true,
        });
      });
  };

  isValidEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.email).toLowerCase());
  }

  render() {
    const { email, error } = this.state;
    return (
      <>
        {this.state.success_message ? (
          <>
            <AlertComponent color="success" className="alert--bordered" icon>
              {this.state.success_message}
            </AlertComponent>
          </>
        ) : (
          ""
        )}
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  onChange={this.handleChange}
                  value={email}
                  name="email"
                />
                {this.state.ErrorMessage.email ? (
                  <div className="form__form-group-error">
                    {this.state.ErrorMessage.email[0]}
                  </div>
                ) : (
                  ""
                )}
                {this.state.ErrorMessage ? (
                  <div className="form__form-group-error">
                    {this.state.ErrorMessage[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <button
            className="btn btn-account account__btn account__btn--small"
            style={{ marginTop: "10px", color: "#fff" }}
            type="submit"
            disabled={this.state.isLoading}
          >
            Forget Password
          </button>
        </form>
      </>
    );
  }
}
function mapState(state) {
  const { loggedIn, isLoading } = state.authentication;
  return { loggedIn, isLoading };
}

const actionCreators = {
  postForgetPassword: userActions.postForgetPassword,
};

export default withRouter(
  connect(mapState, actionCreators)(ForgetPassword),
);