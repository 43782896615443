

// type FetchRespon = {
//     ok: boolean,
//     json: () => Promise<{}>,
//     text: () => Promise<string>,
//     status: number,
//     headers: {
//         get: (key) => string
//     },
// };

class ErrorResponse {
    constructor(response, json = null) {
        const defaultJson = { nonFieldErrors: ['Unknown error occurred'] };
        this.response = response;
        this.json = json || defaultJson;
    }

    json;

    response;
}

export { ErrorResponse };
// export type { FetchRespon };