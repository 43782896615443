

import queryString from "qs";
// import { authHeader } from "./authHeader";
import { CekHttpNetworkResponse } from "./cekResponse";
import { handleResponse } from "./handleResponse";

let API_URL = process.env.REACT_APP_BACKEND_URL;


// if (window.location.hostname === "dev-partners.blankontech.com") {
//   API_URL = "https://dev-api-partners.blankontech.com";
// } else if (window.location.hostname === "staging-partners.blankontech.com") {
//   API_URL = "https://staging-api-partners.blankontech.com";
// } else if (window.location.hostname === "partners.blankontech.com") {
//   API_URL = "https://api-partners.blankontech.com";
// } else if (window.location.hostname === "http://192.168.100.74:3000") {
//   API_URL = "http://192.168.100.74:8002";
// } else {
//   API_URL = "http://localhost:8002";
//   // API_URL = process.env.REACT_APP_API_URL;
//   // API_URL = "http://localhost:8002";
//   // API_URL = "https://dev-api-partners.blankontech.com";
// }

export function buildUrl(endpoint, queryParams = {}) {
  const strQueryParams = queryString.stringify(queryParams);
  const queryStr = strQueryParams ? `?${strQueryParams}` : "";
  const builtEndpoint = `${endpoint}${queryStr}`;
  return endpoint.slice(0, 4) === "http"
    ? builtEndpoint
    : `${API_URL}/${builtEndpoint}`;
}

// const InitOptions = {
//   method?,
//   body?: {},
//   queryParams?: {},
//   headers?: {
//     "Content-Type"?,
//     Authorization,
//   },
// };

export default async function authFetch(
  endpoint,
  init = {},
  rawResponse = false,
) {
  const {
    queryParams,
    headers: givenHeaders = {},
    body: givenBody,
    ...restArgs
  } = init;
  const contentType = givenHeaders["Content-Type"];

  // Access-Control-Allow-Methods

  let body = null;
  if (
    givenBody && !(givenBody instanceof FormData) && (!contentType || contentType === "application/json")
  ) {
    givenHeaders["Content-Type"] = "application/json";
    body = JSON.stringify(givenBody);
  } else {
    body = givenBody;
  }

  const fetchInit = {
    method: "GET",
    ...restArgs,
    body,
    headers: {
      // Authorization: authHeader() || null,
      ...givenHeaders,
    },
  };

  const url = buildUrl(endpoint, queryParams);
  if (rawResponse) {
    return fetch(url, fetchInit);
  }
  return fetch(url, fetchInit).then(CekHttpNetworkResponse).then(handleResponse);
}

// export { fetchInit };
// export type { InitOptions };