

import React from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import ResellerInvoiceList from "../components/ResellerInvoiceList";
import ResellerListClients from "../components/ResellerListClients";

// eslint-disable-next-line react/prefer-stateless-function
class ResellerDashboard extends React.Component {
  render() {
    const { itemsResseler: { results }, invoices } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <ResellerListClients projectList={results} />
          <ResellerInvoiceList invoiceList={invoices} />
        </Row>
      </Container>
    );
  }
}

function mapState(state) {
  const { itemsResseler, invoice: { results } } = state.partnerReseller;
  const invoices = results;
  return { itemsResseler, invoices };
}

const PageReseller = connect(mapState)(ResellerDashboard);
export { PageReseller as ResellerDashboard };