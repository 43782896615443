import React from "react";
import { Table } from "reactstrap";
import PropTypes from "prop-types";

function TableList({ thead, tbody, children }) {
  return (
    <Table responsive className="table--bordered">
      <thead>
        <tr>{thead}</tr>
      </thead>
      <tbody>{tbody}</tbody>
    </Table>
  );
}

// TableList.propTypes = {
//   thead: PropTypes.node.isRequired,
//   tbody: PropTypes.node.isRequired,
//   children: PropTypes.node.isRequired,
// };

export default TableList;
