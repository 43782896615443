

import { faDollarSign, faDownload, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "reactstrap";

const flat = `${process.env.PUBLIC_URL}/img/Artboard_8.svg`;

// eslint-disable-next-line react/prefer-stateless-function
function ModalTransaction({ toggle, handleModal, amount, date, explanation, file }) {
  return (
    <Modal
      isOpen={toggle}
      toggle={handleModal}
      centered
      className="modal-dialog--header modal-dialog--transaction"
    >
      <div className="modal__header modal__header-payment">
        <div className="modal__display">
          <div className="modal__border-transaction">
            <FontAwesomeIcon className="" icon={faWallet} />
          </div>
          <div style={{ marginLeft: "12px" }}>
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>Transaction</span>
          </div>
        </div>
        <div>
          <h3><FontAwesomeIcon className="" icon={faDollarSign} />&nbsp;{parseInt(amount, 10)}</h3>
        </div>
      </div>
      <div className="modal__body">
        <div className="modal__body-payment-transaksi">
          <img style={{ width: "40%", height: "40%" }} className="" src={flat} alt="" />
          <dl style={{ marginTop: "24px" }}>
            <div className="grid-column_transaction">
              <dt>
                Amount
              </dt>
              <dd>
                <h5><FontAwesomeIcon className="" icon={faDollarSign} />&nbsp;{parseInt(amount, 10)}</h5>
              </dd>
            </div>
            <div className="grid-column_transaction">
              <dt>
                Transaction Date
              </dt>
              <dd>
                <h5>{date}</h5>
              </dd>
            </div>
            <div className="grid-column_transaction">
              <dt>
                Description
              </dt>
              <dd>
                <h5>{explanation}</h5>
              </dd>
            </div>
            <div className="grid-column_transaction">
              <dt>
                Invoice
              </dt>
              <dd>
                <a rel="noreferrer" href={file} download target="_blank">
                  <div className="icon_corder icon_download">
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                </a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </Modal>
  );
}

export default ModalTransaction;
