

import React from "react";
import { Link, withRouter } from "react-router-dom";
import EyeIcon from "mdi-react/EyeIcon";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { userActions } from "../../../redux/actions/authActions";
import { ErrorResponse } from "../../../redux/helpers/response";
import AlertComponent from "../../../shared/components/Message/Alert";

// type State = {
//   username,
//   password,
//   isLoading: boolean,
//   ErrorMessage: any,
//   timeout?: number,
//   showPassword: boolean,
//   showSuccess: boolean,
// };

class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
    isLoading: false,
    showPassword: false,
    ErrorMessage: {},
    timeout: null,
    showSuccess: false,
  };

  componentWillUnmount = () => {
    this.clearTimeout();
  };

  clearTimeout = () => {
    const { timeout } = this.state;
    if (timeout) {
      clearTimeout(timeout);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, ErrorMessage: "" });
  };

  showSuccess = () => {
    this.clearTimeout();
    const newTimeout = setTimeout(() => {
      this.setState({ showSuccess: false });
    }, 5000);
    this.setState({ showSuccess: true, timeout: newTimeout, ErrorMessage: {} });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.setState({
      isLoading: true,
    });
    this.props.login(username, password).then(() => {
      this.showSuccess();
      this.setState({ isLoading: false });
    }).catch((error) => {
      const { json } = error;
      let ErrorMessage = "";
      if (!json) {
        ErrorMessage = "Something went wrong.";
      } else if (json.nonFieldErrors) {
        ErrorMessage = json.nonFieldErrors;
      } else {
        ErrorMessage = json;
      }
      this.setState({
        ErrorMessage: ErrorMessage,
        isLoading: false,
        showSuccess: false,
      });
    });
  };

  handleShowPassword = () => {
    this.setState((prev) => ({
      showPassword: !prev.showPassword,
    }));
  };

  render() {
    const {
      username, password, showPassword, showSuccess,
    } = this.state;
    if (this.props.loggedIn) {
      // const BASE_URL = process.env.REACT_APP_BASE_URL;
      window.location.replace(`${process.env.REACT_APP_BASE_URL}affiliate`);
    }
    return (
      <>
        {showSuccess ? (
          <AlertComponent color="success" className="alert--bordered" icon>
            your account success Login
          </AlertComponent>
        ) : (
          ""
        )}
        {this.state.ErrorMessage && Array.isArray(this.state.ErrorMessage) ? (
          <AlertComponent color="danger" className="alert--bordered" icon>
            {this.state.ErrorMessage[0]}
          </AlertComponent>
        ) : (
          ""
        )}
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  type="email"
                  id="username"
                  placeholder="Email"
                  onChange={this.handleChange}
                  value={username}
                  name="username"
                  required
                />
                {this.state.ErrorMessage.username ? (
                  <div className="form__form-group-error">
                    {this.state.ErrorMessage.username[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                  value={password}
                  name="password"
                  required
                />
                <button
                  type="button"
                  className={`form__form-group-button${showPassword ? " active" : ""
                    }`}
                  onClick={this.handleShowPassword}
                >
                  <EyeIcon />
                </button>
                {this.state.ErrorMessage.password ? (
                  <div className="form__form-group-error">
                    {this.state.ErrorMessage.password[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <a href={`${process.env.REACT_APP_BASE_URL}forget-password`} style={{ display: "flex", justifyContent: "end" }} className="btn-copy" id="copy">forget password</a>
          {this.state.isLoading ? (
            <div style={{ marginTop: "12px", display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <button
              className="btn btn-account account__btn account__btn--small"
              style={{ marginTop: "10px", color: "#fff", width: "100%" }}
              type="submit"
            >
              Sign In
            </button>
          )}
          <Link
            className="btn btn-outline-primary account__btn account__btn--small"
            to={`${process.env.REACT_APP_BASE_URL}register`}
            style={{ width: "100%" }}
          >
            Create Account
          </Link>
        </form>
      </>
    );
  }
}

function mapState(state) {
  const { loggedIn, isLoading } = state.authentication;
  return { loggedIn, isLoading };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedLoginPage = withRouter(
  connect(mapState, actionCreators)(LoginForm),
);
export { connectedLoginPage as LoginForm };
