

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";
import PropTypes from "prop-types";
import { Panel, TableList } from "../../../shared/components";

const header = [
  { id: 0, title: "Company" },
  { id: 1, title: "Commission" },
  { id: 2, title: "Status" },
];

// eslint-disable-next-line react/prefer-stateless-function
class ResellerListClients extends React.Component {
  state = {
    project: [],
  };

  render() {
    const { projectList } = this.props;
    return (
      <Panel
        xs={12}
        md={12}
        lg={12}
        title="List Clients Reseller"
        header
      >
        <div dir="ltr">
          {projectList.length === 0 ? (
            <div>
              <h3 style={{ justifyContent: "center", display: "flex" }}>
                <FontAwesomeIcon icon={faHourglass} />
              </h3>
              <p
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "12px",
                }}
              >
                You don&apos;t have any list
              </p>
            </div>
          ) : (
            <TableList
              thead={header.map((item) => (
                <th key={item.id}>{item.title}</th>
              ))}
              tbody={projectList && projectList.map((item) => (
                <tr key={item.id}>
                  <th>{item.name}</th>
                  <th>${parseInt(item.cost, 10)}</th>
                  <th>
                    {item.status === 1 && (
                      <h5>
                        <Badge className="badge-status" pill color="primary">
                          Active
                        </Badge>
                      </h5>
                    )}
                    {item.status === 2 && (
                      <h5>
                        <Badge className="badge-status" pill color="danger">
                          Inactive
                        </Badge>
                      </h5>
                    )}
                  </th>
                </tr>
              ))}
            />
          )}
        </div>
      </Panel>
    );
  }
}

// ResellerListClients.propTypes = {
//   projectList: PropTypes.arrayOf(PropTypes.shape({
//     id: PropTypes.number,
//     name: PropTypes.string,
//     cost: PropTypes.string,
//     status: PropTypes.number,
//   })),
// };

ResellerListClients.defaultProps = {
  projectList: [],
};

export default ResellerListClients;
