

import React from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import { MenuOverView } from "../components/Menu/MenuOverview";
import { ProfileDetail } from "../components/ProfileDetail";

// eslint-disable-next-line react/prefer-stateless-function
class MenuBarProfile extends React.Component {
  render() {
    const {
      user,
    } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <ProfileDetail user={user}>
            <MenuOverView />
          </ProfileDetail>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.userProfile;
  return { user };
}

const MenuBarProfiles = connect(mapStateToProps)(MenuBarProfile);

export default MenuBarProfiles;
