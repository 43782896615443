import { userConstants } from "../constanta/userContant";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {
  loggedIn: true, user, isLoading: true, registering: false, error: {},
} : {};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        error: {},
        loggingIn: false,
        registering: false,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        registering: true,
        isLoading: false,
        user: action.user,
        error: {},
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
        registering: false,
      };
    default:
      return state;
  }
}
