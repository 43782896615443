

import React from "react";
import { Link } from "react-router-dom";
import ArrowLeftBoldBoxOutlineIcon from "mdi-react/ArrowLeftBoldBoxOutlineIcon";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ForgetPassword from '../components/ForgetPAssword';
import { LayoutAuth } from "../../../shared/components/LayoutAuth";

// eslint-disable-next-line react/prefer-stateless-function
class ForgetPasswordPage extends React.Component {
  render() {
    return (
      <LayoutAuth>
        <div className="account__wrapper-position">
          <div className="account__wrapper-arrow">
            <Link to={`${process.env.REACT_APP_BASE_URL}login`}>
              <ArrowLeftBoldBoxOutlineIcon />
            </Link>
          </div>
          <div className="account__wrapper-card">
            <div className="account__wrapper">
              <div className="account__card account__margin">
                <div className="account__head">
                  <h3 className="account__title">Forget Your Password</h3>
                </div>
                <span className="icon__mail">
                  <FontAwesomeIcon className="icon__mail-password" icon={faEnvelope} />
                  <p className="form__group-margin-forget">
                    Enter the email address you used when you joined and we’ll
                    send you instructions to reset your password.
                  </p>
                </span>
                <ForgetPassword />
              </div>
            </div>
          </div>
        </div>
      </LayoutAuth>
    );
  }
}

export default ForgetPasswordPage;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
