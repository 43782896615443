

import authFetch from "../helpers/authFetch";
import { authHeader } from "../helpers/authHeader";

async function AffiliateProject() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return authFetch(`partner/projects`, requestOptions);
}

export const Affiliate = {
    AffiliateProject,
};