import React from "react";
import PropTypes from "prop-types";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import SidebarLink from "./SidebarLink";

function SidebarContentDetail({ onClick }) {
  const handleHideSidebar = () => {
    onClick();
  };

  return (
    <>
      <div className="sidebar__content-detail">
        <ul className="sidebar__block">
          <SidebarLink
            title="Terms&nbsp;&amp;&nbsp;Conditions"
            icon={<AlertCircleIcon size="18px" />}
            route={`${process.env.REACT_APP_BASE_URL}terms-condition`}
            onClick={handleHideSidebar}
          />
        </ul>
      </div>
    </>
  );
}

// SidebarContentDetail.propTypes = {
//   onClick: PropTypes.func.isRequired,
// };

export default SidebarContentDetail;
