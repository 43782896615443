

import { faCalendar, faDollarSign, faDownload, faEye, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

// type Props = {
//   amount,
//   date,
//   file: Object,
//   detail_date,
//   detail_icon,
//   download,
//   getModal: () => void,
// }

// eslint-disable-next-line react/prefer-stateless-function
function HistoryCard({ amount, file, getModal, detail_icon, date, detail_date, download }) {
  return (
    <>
      <div className="card-history" style={{ marginTop: "12px" }}>
        <div className="card-flex">
          <div className="card__title">
            <div className="icon_corder icon_download">
              <FontAwesomeIcon className="color_svg" icon={faWallet} />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <h4 className="text__card_title"><FontAwesomeIcon icon={faDollarSign} />&nbsp;{parseInt(amount, 10)}</h4>
          </div>
          <div style={{ display: "flex" }}>
            <div className="icon_corder icon_download" style={{ cursor: "pointer" }} id={`tooltip-${detail_date}`}>
              <FontAwesomeIcon className="color_svg" icon={faCalendar} />
            </div>
            <a id={`download-${download}`} rel="noreferrer" href={file} download target="_blank">
              <div className="icon_corder icon_download">
                <FontAwesomeIcon className="color_svg" icon={faDownload} />
              </div>
            </a>
            <button
              className="icon_corder icon_download"
              onClick={getModal}
              type="button"
            >
              <FontAwesomeIcon
                className="color_svg"
                style={{ cursor: "pointer" }}
                id={`detail-${detail_icon}`}
                icon={faEye}
              />
            </button>
          </div>
        </div>
      </div>
      <UncontrolledTooltip placement="top" target={`tooltip-${detail_date}`}>
        {date}
      </UncontrolledTooltip>
    </>
  );
}

export default HistoryCard;
