import { AffiliateConstants } from "../constanta/AffiliateContant";

const initialState = {
  items: [],
  payout: [],
  loading: false,
};

export default function partnerAffiliate(state = initialState, actions) {
  switch (actions.type) {
    case AffiliateConstants.AFFILIATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AffiliateConstants.AFFILIATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: actions.affiliate,
      };
    case AffiliateConstants.AFFILIATE_FAILURE:
      return {
        ...state,
        items: actions.error,
      };
    case AffiliateConstants.PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AffiliateConstants.PROJECT_SUCCESS:
      return {
        ...state,
        project: actions.project,
      };
    case AffiliateConstants.PROJECT_FAILURE:
      return {
        ...state,
        project: actions.error,
      };
    case AffiliateConstants.PAYOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AffiliateConstants.PAYOUT_SUCCESS:
      return {
        ...state,
        payout: actions.payout,
      };
    case AffiliateConstants.PAYOUT_FAILURE:
      return {
        ...state,
        payout: actions.error,
      };
    default:
      return state;
  }
}
