

import React from "react";
import { Switch, withRouter } from "react-router-dom";
import Layout from "../Layout/index";
import { AffiliateView } from "../Affiliate/views/AffiliateView";
import { ResellerDashboard } from "../Resellers/views/ResellerDashboard";
import MenuBarProfiles from "../Profiles/views/MenuBarProfile";
import EditProfiles from "../Profiles/views/EditProfileView";
import PrivateRoute from "./PrivateRouter";
import ProfileViews from "../Profiles/views/ProfileView";
import Payments from "../Profiles/Billing/Payment";
import withPageView from "../../shared/components/GoggleAnalytic";

// type RouteDef = {
//   path,
//   component: React.ComponentType,
//   exact: boolean,
// };
const BASE_PATH = process.env.REACT_APP_BASE_URL;

const RoutePages = [
  {
    path: `${BASE_PATH}affiliate`,
    component: AffiliateView,
    exact: true,
  },
  {
    path: `${BASE_PATH}overview`,
    component: MenuBarProfiles,
    exact: true,
  },
  {
    path: `${BASE_PATH}reseller`,
    component: ResellerDashboard,
    exact: true,
  },
  {
    path: `${BASE_PATH}edit-profile`,
    component: EditProfiles,
    exact: true,
  },
  {
    path: `${BASE_PATH}profile`,
    component: ProfileViews,
    exact: true,
  },
  {
    path: `${BASE_PATH}payment`,
    component: Payments,
    exact: true,
  },
];

function Pages() {
  return (
    <div>
      <Switch>
        <>
          <Layout />
          <div className="container__wrap">
            {RoutePages.map((u) => (
              <PrivateRoute
                key={u.path}
                path={u.path}
                exact={u.exact}
                component={withPageView((props) => <u.component {...props} />)}
              />
            ))}
          </div>
          {/* <Route path="*" component={NotFound} /> */}
        </>
      </Switch>

    </div>
  );
}

export default withRouter(Pages);
