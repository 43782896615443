

import React from "react";
import { blockInvalidChar, blockInvalidSpecial } from "../../../shared/helpers";


function ModalFormBank({
  submitted, onChange: onInputChange, bankPayments, selectedValue, maxLength, errors, errorsEuro,
}) {
  const onChange = (event) => {
    onInputChange({ bankPayments: { ...bankPayments, [event.target.name]: event.target.value } });
  };
  return (
    <>
      <div>
        <div className="form__form-group">
          <span className="form__form-group-label">Bank Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-input-wrap">
              <input
                type="text"
                id="bank_name"
                value={bankPayments.bank_name}
                placeholder="Bank Name"
                onChange={onChange}
                name="bank_name"
              />
              {submitted && !bankPayments.bank_name && (
                <div
                  id="validationServer03Feedback"
                  className="form__form-group-error"
                >
                  This field is required
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-input-wrap">
              <input
                type="text"
                id="name"
                value={bankPayments.name}
                placeholder="Name"
                onChange={onChange}
                name="name"
              />
              {submitted && !bankPayments.name && (
                <div
                  id="validationServer03Feedback"
                  className="form__form-group-error"
                >
                  This field is required
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {selectedValue === "USD" ? <>Routing Number</> : <>BIC</>}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-input-wrap">
              <input
                type={selectedValue === "USD" ? "number" : "text"}
                maxLength={selectedValue === "USD" ? "9" : "11"}
                onKeyDown={selectedValue === "USD" ? blockInvalidChar : blockInvalidSpecial}
                // onKeyPress={selectedValue === "USD" ? "" : "return /[0-9a-zA-Z]/i.test(event.key)"}
                onInput={() => { selectedValue === "USD" ? maxLength : "" }}
                id={selectedValue === "USD" ? "routing_number" : "bic"}
                placeholder={
                  selectedValue === "USD" ? "Routing Number" : "BIC"
                }
                value={
                  selectedValue === "USD"
                    ? bankPayments.routing_number
                    : bankPayments.bic
                }
                name={selectedValue === "USD" ? "routing_number" : "bic"}
                onChange={onChange}
              />
              {errors}
              {errorsEuro}
              {selectedValue === "USD" && submitted && !bankPayments.routing_number && (
                <div
                  id="validationServer03Feedback"
                  className="form__form-group-error"
                >
                  This field is required
                </div>
              )}
              {selectedValue !== "USD" && submitted && !bankPayments.bic && (
                <div
                  id="validationServer03Feedback"
                  className="form__form-group-error"
                >
                  This field is required
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {selectedValue === "USD" ? <>Account Number</> : <>IBAN</>}
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-input-wrap">
              <input
                type={selectedValue === "USD" ? "number" : "text"}
                onKeyDown={selectedValue === "USD" ? blockInvalidChar : blockInvalidSpecial}
                onInput={() => { selectedValue === "USD" ? maxLength : "" }}
                maxLength={selectedValue === "USD" ? "17" : "34"}
                id={
                  selectedValue === "USD" ? "account_number" : "iban"
                }
                placeholder={
                  selectedValue === "USD" ? "Account Number" : "IBAN"
                }
                value={
                  selectedValue === "USD"
                    ? bankPayments.account_number
                    : bankPayments.iban
                }
                name={
                  selectedValue === "USD" ? "account_number" : "iban"
                }
                onChange={onChange}
              />
              {selectedValue === "USD" && submitted && !bankPayments.account_number && (
                <div
                  id="validationServer03Feedback"
                  className="form__form-group-error"
                >
                  This field is required
                </div>
              )}
              {selectedValue === "EURO" && submitted && !bankPayments.iban && (
                <div
                  id="validationServer03Feedback"
                  className="form__form-group-error"
                >
                  This field is required
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// ModalFormBank.propTypes = {
//     selectedValue: PropTypes.string,
// };

ModalFormBank.defaultProps = {
  selectedValue: "",
};

export default ModalFormBank;
