

export const handleResponse = (response) => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  }
  return response.text();
};
let BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;
// console.log(process.env);
export const API_URL = BACKEND_API_URL;
