import { PaymentConstants } from "../constanta/PaymentContant";
import authFetch from "../helpers/authFetch";
import { authHeader } from "../helpers/authHeader";
import { CekHttpNetworkResponse } from "../helpers/cekResponse";
import { API_URL, handleResponse } from "../helpers/handleResponse";


function createPaymentMethod(data, type) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ type, data }),
  };

  return async (dispatch) => {
    request(data);
    try {
      const req = await fetch(
        `${API_URL}/account/payout/create`,
        requestOptions).then(CekHttpNetworkResponse); //.then(handleResponse);
      // const response_1 = await CekHttpNetworkResponse(response);
      const response = await handleResponse(req);
      dispatch(success(response));
    } catch (error) {
      dispatch(failure(error));
    }
  }
  function request(payment) {
    return { type: PaymentConstants.PAYMENT_REQUEST, payment };
  }
  function success(payment) {
    return { type: PaymentConstants.PAYMENT_SUCCESS, payment };
  }
  function failure(error) {
    return { type: PaymentConstants.PAYMENT_FAILURE, error };
  }
}

function listPaymentMethod() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return (dispatch) => {
    dispatch(request());
    return authFetch(`account/payout`, requestOptions)
      .then((listPayment) => {
        dispatch(success(listPayment));
      });
  };
  function request() {
    return { type: PaymentConstants.PAYMENTS_REQUEST };
  }
  function success(listPayment) {
    return { type: PaymentConstants.PAYMENTS_SUCCESS, listPayment };
  }
  function failure(error) {
    return { type: PaymentConstants.PAYMENTS_FAILURE, error };
  }
}

function updatePaymentMethod(data, type, id) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ data, type }),
  };
  return (dispatch) => {
    dispatch(request(data, id));
    return fetch(`${API_URL}/account/payout/update/${id}`, requestOptions).then(CekHttpNetworkResponse).then(handleResponse)
      .then((payment) => {
        dispatch(success(payment));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
  function request(payment, id) {
    return { type: PaymentConstants.PAYMENTS_UPDATE_REQUEST, payment };
  }
  function success(payment) {
    return { type: PaymentConstants.PAYMENTS_UPDATE_SUCCESS, payment };
  }
  function failure(error) {
    return { type: PaymentConstants.PAYMENT_FAILURE, error };
  }
}

function deletePaymentMethod(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return (dispatch) => {
    dispatch(request(id));
    return fetch(`${API_URL}/account/payout/delete/${id}`, requestOptions)
      .then(CekHttpNetworkResponse).then(handleResponse)
      .then(
        (payment) => {
          dispatch(success(id));
        },
      ).catch((error) => {
        dispatch(failure(id, error));
      });
  };
  function request(id) {
    return { type: PaymentConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: PaymentConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: PaymentConstants.DELETE_FAILURE, id, error };
  }
}

export const paymentActions = {
  createPaymentMethod,
  listPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
};
