

import React from "react";


function CardBank({ card_numer, name, currency, bank_name, children }) {
  return (
    <div className="card-payment-template">
      <div className="card__front card__part">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="card__payment-title">{bank_name}</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {children}
          </div>
        </div>
        <>
          <span className="card_numer">
            {card_numer}
          </span>
          <div className="card__space-75">
            <span className="card__label">Name</span>
            <div className="card__info">{name}</div>
          </div>
          <div className="card__space-25">
            <span className="card__label">Currency</span>
            <div className="card__info">
              {currency}
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default CardBank;
