import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import WeatherNightIcon from "mdi-react/WeatherNightIcon";
import WhiteBalanceSunnyIcon from "mdi-react/WhiteBalanceSunnyIcon";

function TopDarkMode({ changeToDark, changeToLight, darkMode }) {
  return (
    <div className="topbar-dark_mode">
      {darkMode ? (
        <IconButton onClick={changeToLight}>
          <WhiteBalanceSunnyIcon color="orange" />
        </IconButton>
      ) : (
        <>
          <IconButton onClick={changeToDark}>
            <WeatherNightIcon color="black" />
          </IconButton>
        </>
      )}
    </div>
  );
}

// TopDarkMode.propTypes = {
//   darkMode: PropTypes.bool.isRequired,
//   changeToDark: PropTypes.func.isRequired,
//   changeToLight: PropTypes.func.isRequired,
// };
export default TopDarkMode;
