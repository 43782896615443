import React from "react";
import { Link } from "react-router-dom";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";

const blankon = `${process.env.REACT_APP_BASE_URL}img/favblannkon.webp`;
const tos = `${process.env.REACT_APP_BASE_URL}TOS.pdf`;

// eslint-disable-next-line react/prefer-stateless-function
class Tos extends React.Component {
  render() {
    return (
      <div className="tos__dashboard">
        <div className="tos__dashboard-header">
          <div className="tos__dashboard-header-back">
            <Link to={`${process.env.REACT_APP_BASE_URL}affiliate`}>
              <ArrowLeftIcon style={{ color: "#340dd1" }} />
            </Link>
          </div>
          <Link className="face-button" to={tos} download target="_blank">
            {" "}
            <div className="face-primary">
              <span className="icon fa fa-cloud">{/* sad */}</span>
              Download
            </div>
            <div className="face-secondary">
              <span className="icon fa fa-hdd-o">{/* asd */}</span>
              Size: 315kb
            </div>
          </Link>
          {/* </ModalBtn> */}
        </div>
        <h3 className="tos__dashboard-title">Terms and Condition</h3>
        <h4 className="tos__dashboard-title-1">
          BlankonTech Affiliate Program
        </h4>
        <div>
          <h5>Registration &amp; Terms</h5>
          <p className="tos__dashboard-content">
            You must provide your legal full name, a valid email address, and
            any other information requested in order to complete the sign up
            process for an Affiliate account (“account”).
          </p>
          {/* <br /> */}
          <p className="tos__dashboard-content">
            You must be 18 years of age or older to join this Program.
          </p>
          {/* <br /> */}
          <p className="tos__dashboard-content">
            Each account is for use by either a single legal entity (e.g. a
            company or a partnership) or an individual user. We do not permit
            you to share your user name and password with any other person nor
            with multiple users on a network. Responsibility for the security of
            any usernames and passwords issued (including those of any Invitees)
            rests with you.
          </p>
          {/* <br /> */}
          <p className="tos__dashboard-content">
            You may not use the Program for any illegal or unauthorized purpose.
            You must not -in the use of the Program- violate any laws in your
            jurisdiction (including but not limited to copyright laws).
          </p>
          <br />
          <h5>Referral Links &amp; Promotion</h5>
          <p className="tos__dashboard-content">
            Once you have signed up for the Program you will be provided with a
            URL link that must be used to identify you when placing a link from
            your site, email or other communications to the BlankonTech website.
            It is your responsibility to ensure each such link is correctly
            formatted.
          </p>
          <p className="tos__dashboard-content">
            We may also provide graphical images that can be used within the
            links to promote Blankontech. You may not modify these images in any
            way. We reserve the right to change the images at any time without
            notice.
          </p>
          <p className="tos__dashboard-content">
            You will be solely responsible for the development, operation, and
            maintenance of your site and for all materials that appear on your
            site
          </p>
          <p className="tos__dashboard-content">
            You may not use our name or graphics in any bulk email whatsoever
            unless we have given our advanced written consent. We may terminate
            the Agreement if any meaningful spam complaints naming us or our
            services result from your marketing activities.
          </p>
          <br />
          <h5>Referral Fees</h5>
          <p className="tos__dashboard-content">
            For the sale of a subscription to be eligible to earn a referral
            fee, the client must click-through a link from your site, email, or
            other communications to the Blankon website and set up a call to
            make a deal within 20 days of the initial click-through. If they
            fail to set up a call and make a deal within those 20 days and later
            return without following your link, you will not earn a referral
            fee.
          </p>
          <p className="tos__dashboard-content">
            We will only pay referral fees on links that are automatically
            tracked and reported by our systems. For our systems to track the
            referral, the visitor must have cookies enabled. We will not pay
            referral fees if someone says they signed up through you but it was
            not tracked by our system.
          </p>
          <p className="tos__dashboard-content">
            The referral fee is 4% from each project of our revenue from
            customers that you refer, which is a minimum of $230, and get an
            increase if you can get a bigger project. The commission you get
            will be sent monthly as long as the client you refer to is still
            working with us. The referral fee will be credited to your Affiliate
            account once the customer pays their subscription. Referral fees are
            only earned if a customer makes a payment in full.
          </p>
          <br />
          <h5>Payment</h5>
          <p className="tos__dashboard-content">
            Accrued referral fees are paid via bank transfer or paypal. The
            Affiliate will then raise an invoice to BlankonTech for the
            indicated amount. You must have a valid email that connects paypal
            and a valid bank account to receive referral fees, as we do not
            offer payment via cheque/check, credit card, cash or other method.
          </p>
          <p className="tos__dashboard-content">
            Customer payments refunded or payments charged-back due to credit
            card fraud do not qualify for referral fees. We may delay crediting
            of referral fees subject to risk analysis considerations and
            Anti-Money Laundering procedures.
          </p>
          <p className="tos__dashboard-content">
            A summary of sign ups and statement of referral fees is available to
            the Affiliate by logging into their Affiliate account.
          </p>
          <p className="tos__dashboard-content">
            Account payment will be released on 20th every month (as long as the
            project is running).
          </p>
          <p className="tos__dashboard-content">
            The referral fee structure is subject to change at our discretion.
          </p>
          <p className="tos__dashboard-content">
            We reserve the right to disqualify referral fees earned through
            fraudulent, illegal, or overly aggressive, questionable sales or
            marketing methods.
          </p>
          <p className="tos__dashboard-content">
            All fees are exclusive of all taxes, charges, levies, assessments
            and other fees of any kind imposed on your involvement in this
            Agreement and shall be the responsibility of, and payable by you.
          </p>
          <p className="tos__dashboard-content">
            We reserve the right to check and change commissions on the basis of
            orders actually paid, the notification e-mail is not understood as a
            confirmed commission - this is only a notification, and every
            payment will be verified based on real transactions.
          </p>
          <br />
          <h5>Client Definition</h5>
          <p className="tos__dashboard-content">
            Every client who buys a service through this program is deemed to be
            a client of BlankonTech. Accordingly, all of our rules, policies,
            and operating procedures concerning pricing, client orders, client
            service, and services sales will apply to those client. We may
            change our policies and operating procedures at any time.
            BlankonTech is not responsible for any representations made by the
            Affiliate that contradict our rules, policies or operating
            procedures.
          </p>
          <br />
          <h5>Pricing &amp; Availability</h5>
          <p className="tos__dashboard-content">
            We will determine the prices to be charged for services sold under
            this Program in accordance with our own pricing policies. Services
            prices and availability may vary from time to time. Because price
            changes may affect services that you have listed on your site, you
            should not display services prices on your site. We will use
            commercially reasonable efforts to present accurate information, but
            we cannot guarantee the availability or price of any particular
            service.
          </p>
          <br />
          <h5>Copyrighted and Trademarked material</h5>
          <p className="tos__dashboard-content">
            You are solely responsible for ensuring that your reviews, product
            descriptions and articles (if applicable at your site) obey all
            applicable copyright, trademark, and other laws. Blankon Technology
            Solution will not be responsible if you use another party&apos;s
            copyrighted or trademarked material in violation of the law.
          </p>
          <br />
          <h5>Term of the Agreement and Program </h5>
          <p className="tos__dashboard-content">
            The term of this Agreement will begin upon our acceptance of your
            Program application and will end when terminated by either party.
            Either you or we may terminate this Agreement at any time, with or
            without cause, by giving the other party notice of termination.
            Notice by e-mail, to your address on our records, is considered
            sufficient notice to terminate this Agreement. BlankonTech
            reservesthe right to end the Program at any time. Upon Program
            termination, BlankonTech will pay any legitimate outstanding
            earnings.
          </p>
          <br />
          <h5>Termination</h5>
          <p className="tos__dashboard-content">
            BlankonTech, in its sole discretion, has the right to suspend or
            terminate your account and refuse any and all current or future use
            of the Program, or any other BlankonTech service, for any reason at
            any time. Such termination will result in the deactivation or
            deletion of your Affiliate Account, and the forfeiture and
            relinquishment of all potential or accrued referral fees in your
            Account if they were earned through fraudulent, illegal, or overly
            aggressive, questionable sales or marketing methods. BlankonTech
            reserves the right to refuse service to anyone for any reason at any
            time. Upon the termination of this Agreement for any reason, you
            will immediately cease use of, and remove from your site, all links
            to the BlankonTech website and all our images and other materials
            provided under the Program.
          </p>
          <br />
          <h5>Relationship of Parties</h5>
          <p className="tos__dashboard-content">
            You and we are independent contractors, and nothing in this
            Agreement will create any partnership, joint venture, agency,
            franchise, sales representative, or employment relationship between
            the parties. You will have no authority to make or accept any offers
            or representations on our behalf. You will not make any statement,
            whether on your site or otherwise, that reasonably would contradict
            anything in this Agreement.
          </p>
          <br />
          <h5>Limitations of Liability </h5>
          <p className="tos__dashboard-content">
            The Company and any of the Company&apos;s officers, directors,
            employees, shareholders or agents of any of them, exclude all
            liability and responsibility for any amount or kind of loss or
            damage that may result to you or a third party (including without
            limitation, any direct, indirect, punitive or consequential loss or
            damages, or any loss of income, profits, goodwill, data, contracts,
            use of money, or loss or damages arising from or connected in any
            way to business interruption, and whether in tort (including without
            limitation negligence), contract or otherwise) in connection with
            this Program. Nothing in this legal notice shall exclude or limit
            the Company&apos;s liability for:
            <ul className="tos__dashboard-content-list">
              <li>
                death or personal injury caused by negligence (as such term is
                defined by the Unfair Contract Terms Act 1977
              </li>
              <li>fraud</li>
              <li>misrepresentation as to a fundamental matter</li>
              <li>
                any liability which cannot be excluded or limited under
                applicable law.
              </li>
            </ul>
          </p>
          <p className="tos__dashboard-content">
            If your use of material provided under this Program results in the
            need for servicing, repair or correction of equipment, software or
            data, you assume all costs thereof. The Company’s maximum aggregate
            liability under or in connection with these Terms, or any collateral
            contract, whether in contract, tort (including negligence) or
            otherwise (a “Claim”), shall be limited to a sum equal to the
            aggregate amount which we are obliged to pay you in the twelve (12)
            month period immediately prior to the period giving rise to such
            Claim.
          </p>
          <br />
          <h5>Arbitration</h5>
          <p className="tos__dashboard-content">
            Any dispute relating in any way to this Agreement (including any
            actual or alleged breach hereof), any transactions or activities
            under this Agreement or your relationship with us or any of our
            affiliates shall be submitted to confidential arbitration in
            Jakarta, Indonesia. Arbitration under this agreement shall be
            conducted under the rules then prevailing of the Indonesian
            Arbitration Association. The arbitrator&apos;s award shall be
            binding and may be entered as a judgment in any court of competent
            jurisdiction. To the fullest extent permitted by applicable law, no
            arbitration under this Agreement shall be joined to an arbitration
            involving any other party subject to this Agreement, whether through
            class arbitration proceedings or otherwise.
          </p>
          <br />
          <h5>Notice</h5>
          <p className="tos__dashboard-content">
            All notices given by you to us must be given Blankon technology
            Solution at info@blankontech.com. We may give notice to you at the
            e-mail address you provided to us when registering. Notice will be
            deemed received and properly served 24 hours after an e-mail is
            sent. In proving the service of any notice, it will be sufficient to
            prove in the case of an e-mail, that such e-mail was sent to the
            specified e-mail address of the addressee.
          </p>
          <br />
          <h5>Events outside our control</h5>
          <p className="tos__dashboard-content">
            We will not be liable or responsible for any failure to perform, or
            delay in performance of, any of our obligations hereunder that is
            caused by events outside our reasonable control (a “Force Majeure
            Event”).
          </p>
          <p className="tos__dashboard-content">
            A Force Majeure Event includes any act, event, non-happening,
            omission or accident beyond our reasonable control and includes in
            particular (without limitation) the following:
            <ul className="tos__dashboard-content-list">
              <li>strikes, lock-outs or other industrial action</li>
              <li>
                civil commotion, riot, invasion, terrorist attack or threat of
                terrorist attack, war (whether declared or not) or threat or
                preparation for war
              </li>
              <li>
                fire, explosion, storm, flood, earthquake, subsidence, epidemic
                or other natural disaster
              </li>
              <li>
                impossibility of the use of public or private telecommunications
                networks
              </li>
              <li>
                the acts, decrees, legislation, regulations or restrictions of
                any government.
              </li>
            </ul>
          </p>
          <p className="tos__dashboard-content">
            Our performance is deemed to be suspended for the period that the
            Force Majeure Event continues, and we will have an extension of time
            for performance for the duration of that period. We will use our
            reasonable endeavors to bring the Force Majeure Event to a close or
            to find a solution by which our obligations under these Terms may be
            performed despite the Force Majeure Event.
          </p>
          <br />
          <h5>Waiver</h5>
          <p className="tos__dashboard-content">
            If we fail, at any time to insist upon strict performance of any of
            your obligations under these Terms, or if we fail to exercise any of
            the rights or remedies to which we are entitled hereunder, this
            shall not constitute a waiver of such rights or remedies and shall
            not relieve you from compliance with such obligations.
          </p>
          <p className="tos__dashboard-content">
            A waiver by us of any default shall not constitute a waiver of any
            subsequent default.
          </p>
          <p className="tos__dashboard-content">
            No waiver by us of any of these Terms shall be effective unless it
            is expressly stated to be a waiver and is communicated to you in
            writing.
          </p>
          <br />
          <h5>Entire agreement</h5>
          <p className="tos__dashboard-content">
            These Terms and any document expressly referred to in it represents
            the entire agreement between us in relation to the use of the
            Program and supersedes any prior agreement, understanding or
            arrangement between us, whether oral or in writing.
          </p>
          <p className="tos__dashboard-content">
            We each acknowledge that, in entering into these Terms, neither of
            us has relied on any representation, undertaking or promise given by
            the other or be implied from anything said or written in
            negotiations between us prior to entering into these Terms except as
            expressly stated herein.
          </p>
          <p className="tos__dashboard-content">
            Neither of us shall have any remedy in respect of any untrue
            statement made by the other, whether orally or in writing, prior to
            the date we entered into these Terms (unless such untrue statement
            was made fraudulently) and the other party&apos;s only remedy shall
            be for breach of contract as provided in these Terms.
          </p>
          <br />
          <h5>Governing law and jurisdiction</h5>
          <p className="tos__dashboard-content">
            This legal notice shall be governed by and construed in accordance
            with indonesian law. Disputes arising in connection with this legal
            notice shall be subject to the exclusive jurisdiction of the
            Indonesian Courts.
          </p>
          <br />
          <p className="tos__dashboard-content-1">
            By signing up to our affiliate program, its mean you agree of Term
            and Condition above. please ensure that you read them carefully.
          </p>
          <p className="tos__dashboard-content-1">
            We reserve the right to update and change the Terms from time to
            time without notice. Any amendments, modifications, enhancements or
            changes to the Program including the release of new features and
            resources made available by us from time to time shall be subject to
            these Terms. Continued use of the Program after any such changes
            shall constitute your consent to such changes. You can review the
            most current version of the Terms at any time.
          </p>
        </div>
        <div className="tos__dashboard-header-image">
          <img src={blankon} alt="blankon" />
          <h4 style={{ marginLeft: "12px" }}>
            Blankontech Partnership Program
          </h4>
        </div>
      </div>
    );
  }
}

const TosPage = Tos;
export { TosPage as Tos };
