

import React from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { profileActions } from "../../../../redux/actions/userAuth";
import { Panel } from "../../../../shared/components";
import { ModalSuccess, ModalWarning } from "../../../../shared/components/ModalAllert";

// eslint-disable-next-line react/prefer-stateless-function
class MenuSettings extends React.Component {
  state = {
    first_name: "",
    last_name: "",
    email: "",
    affiliate_code: "",
    ErrorMessage: {},
    error: {},
    isLoading: false,
    modal_success: {
      message: null,
      toggle: false,
    },
    modal_warning: {
      message: null,
      toggle: false,
    },
  };

  handleCloseModalSuccess = () => {
    this.setState({
      modal_success: {
        message: null,
        toggle: false,
      },
    });
  }

  handleShowModalSuccess = (message) => {
    this.setState({
      modal_success: {
        message: message,
        toggle: true,
      },
    });
  }

  handleCloseModalWarning = () => {
    this.setState({
      modal_warning: {
        message: null,
        toggle: false,
      },
    });
  }

  showModalWarning = (message) => {
    this.setState({
      modal_warning: {
        message: message,
        toggle: true,
      },
      deleteError: null,
    });
  }

  handle5xxError = (error) => {
    if (error.response) {
      if (error.response.status >= 500) {
        this.setState({
          ErrorMessage: {},
          isLoading: false,
        });
        this.showModalWarning("OOPS! Something went wrong. Please try again or contact customer support");
        throw error;
      }
    }
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      affiliate_code: user.affiliate_code,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    const { user } = this.props;
    event.preventDefault();
    const {
      first_name, last_name, email, affiliate_code,
    } = this.state;
    this.setState({
      isLoading: true,
      ErrorMessage: {},
    });
    if (user.affiliate_code === null) {
      this.props
        .update(first_name, last_name, email, affiliate_code)
        .then(() => {
          this.setState({
            isLoading: false,
            ErrorMessage: {},

          });
          this.handleShowModalSuccess("Profile updated successfully");
        })
        .catch((error) => {
          try {
            this.handle5xxError(error);
          } catch (error) {
            return;

          }
          const { json } = error;
          let ErrorMessage = "";
          if (!json) {
            this.showModalWarning("OOPS! Something went wrong. Please try again or contact customer support");
          } else if (json.nonFieldErrors) {
            ErrorMessage = json.nonFieldErrors;
          } else {
            ErrorMessage = json;
          }
          this.setState({
            ErrorMessage: ErrorMessage,
            isLoading: false,
          });
        });
    } else {
      this.props
        .update(first_name, last_name, email)
        .then(() => {
          this.setState({
            isLoading: false,
            ErrorMessage: {},
          });
          this.handleShowModalSuccess("Profile updated successfully");
        })
        .catch((error) => {
          try {
            this.handle5xxError(error);
          } catch (error) {
            return;

          }
          const { json } = error;
          let ErrorMessage = "";
          if (!json) {
            this.showModalWarning(ErrorMessage);
          } else if (json.nonFieldErrors) {
            ErrorMessage = json.nonFieldErrors;
          } else {
            ErrorMessage = json;
          }
          this.setState({
            ErrorMessage: ErrorMessage,
            isLoading: false,
          });
        });
    }
  };
  setDefaultState = () => { // NOSONAR
    const { user } = this.props;

    this.setState({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      affiliate_code: user.affiliate_code !== undefined ? user.affiliate_code : "",
    });
  };

  render() {
    const { user } = this.props;
    return (
      <>
        <Panel xs={12} md={12} lg={12} title="Profile Setting" header>
          <form onSubmit={this.handleSubmit} className="form">
            <div className="profile__overview profile__overview__margin">
              <h5 className="profile__overview_text">Full Name</h5>
              <div className="profile__settings__form">
                <div className="form__form-group-flex">
                  <div
                    className="form__form-group-field"
                    style={{ marginRight: "16px" }}
                  >
                    <div className="form__form-group-input-wrap error">
                      <input
                        type="text"
                        id="first_name"
                        placeholder="First Name"
                        onChange={this.handleChange}
                        value={this.state.first_name || ""}
                        name="first_name"
                        required
                      />
                      {this.state.ErrorMessage.first_name ? (
                        <div className="form__form-group-error">
                          {this.state.ErrorMessage.first_name[0]}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap error">
                      <input
                        type="text"
                        id="last_name"
                        placeholder="Last Name"
                        onChange={this.handleChange}
                        value={this.state.last_name || ""}
                        name="last_name"
                        required
                      />
                      {this.state.ErrorMessage.last_name ? (
                        <div className="form__form-group-error">
                          {this.state.ErrorMessage.last_name[0]}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile__overview profile__overview__margin">
              <h5 className="profile__overview_text">
                {user.affiliate_code === null ? <>Affiliate</> : <>Affiliate Link</>}
              </h5>
              <div className="profile__settings__form">
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap error">
                    <input
                      type="text"
                      id="affiliate_code"
                      placeholder="Affiliate Code"
                      onChange={this.handleChange}
                      // value={this.state.affiliate_code || ""}
                      value={user.affiliate_code === null ? this.state.affiliate_code : `https://blankontech.com/?affiliate=${user.affiliate_code}`}
                      name="affiliate_code"
                      disabled={user.affiliate_code === null ? false : true}
                    />
                    {this.state.ErrorMessage.affiliate_code ? (
                      <div className="form__form-group-error">
                        {this.state.ErrorMessage.affiliate_code[0]}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.ErrorMessage.affiliateCode ? (
                      <div className="form__form-group-error">
                        {this.state.ErrorMessage.affiliateCode[0]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="profile__overview profile__overview__margin">
              <h5 className="profile__overview_text">Email</h5>
              <div
                className="profile__settings__form"
                style={{ alignContent: "end" }}
              >
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap error">
                    <input
                      type="email"
                      id="email"
                      placeholder="Email"
                      onChange={this.handleChange}
                      value={this.state.email || ""}
                      name="email"
                    />
                  </div>
                </div>
                {this.state.ErrorMessage.email ? (
                  <div className="form__form-group-error">
                    {this.state.ErrorMessage.email[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {this.state.isLoading ? (
              <div style={{ marginTop: "12px", display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="btn btn-account account__btn account__btn--small"
                  style={{ marginTop: "10px", color: "#fff" }}
                  type="submit"
                  disabled={this.state.isLoading}
                >
                  <>Update Profile</>
                </button>
              </div>
            )}
          </form>
          <ModalSuccess
            toggle={this.state.modal_success.toggle}
            message={this.state.modal_success.message}
            closeModal={() => this.handleCloseModalSuccess()}
          ></ModalSuccess>
          <ModalWarning
            toggle={this.state.modal_warning.toggle}
            message={this.state.modal_warning.message}
            closeModal={() => this.handleCloseModalWarning()}
          ></ModalWarning>
        </Panel>
      </>
    );
  }
}

function mapState(state) {
  const { userProfile } = state;
  const { user, loading } = userProfile;
  return { user, loading };
}

const actionCreators = {
  update: profileActions.updateUser,
};

const menuSettings = connect(mapState, actionCreators)(MenuSettings);
export { menuSettings as MenuSettings };
