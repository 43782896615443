import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ title, icon, newLink, route, onClick, icons, toc }) => (
  <>
    {toc ? (
      <a
        rel="noreferrer"
        download
        target="_blank"
        href={route}
        activeClassName="sidebar__link-active"
      >
        <li className="sidebar__link">
          {icon ? <span className="sidebar__link-icon lnr">{icon}</span> : ""}
          <p className="sidebar__link-title">
            {title}
            {newLink ? (
              <Badge className="sidebar__link-badge">
                <span>New</span>
              </Badge>
            ) : (
              ""
            )}
          </p>
        </li>
      </a>
    ) : (
      <NavLink
        to={route}
        onClick={onClick}
        activeClassName="sidebar__link-active"
      >
        <li className="sidebar__link">
          {icon ? <span className="sidebar__link-icon lnr">{icon}</span> : ""}
          <p className="sidebar__link-title">
            {title}
            {newLink ? (
              <Badge className="sidebar__link-badge">
                <span>New</span>
              </Badge>
            ) : (
              ""
            )}
          </p>
        </li>
      </NavLink>
    )}
  </>
);

// SidebarLink.propTypes = {
//   title: PropTypes.string.isRequired,
//   icon: PropTypes.string,
//   newLink: PropTypes.bool,
//   route: PropTypes.string,
//   onClick: PropTypes.func,
// };

SidebarLink.defaultProps = {
  icon: "",
  newLink: false,
  route: process.env.REACT_APP_BASE_URL,
  onClick: () => { },
};

export default SidebarLink;
