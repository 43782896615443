import { userConstants } from "../constanta/userContant";

export default function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: false, isLoading: true };
    case userConstants.REGISTER_SUCCESS:
      return { registering: true, isLoading: false };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        registering: false,
        isLoading: true,
        error: action.error,
      };
    default:
      return state;
  }
}