

import React from "react";
import { Badge } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import { Panel, TableList } from "../../../shared/components";

const header = [
  { id: 0, title: "Company" },
  { id: 1, title: "Commission" },
  { id: 2, title: "Status" },
];



// eslint-disable-next-line react/prefer-stateless-function
class AffiliateListClients extends React.Component {
  state = {
    toggle: false,
    isOpen: false,
    list: [],
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  };

  handleOpen = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { listAffiliate } = this.props;
    return (
      <Panel
        xs={12}
        md={12}
        lg={12}
        title="EXPECTED AFFILIATE CLIENT OF THE MONTH"
        header
      >
        {listAffiliate.length === 0 ? (
          <div>
            <h3 style={{ justifyContent: "center", display: "flex" }}><FontAwesomeIcon icon={faHourglass} /></h3>
            <p style={{ justifyContent: "center", display: "flex", marginTop: "12px" }}>You don&apos;t have any client</p>
          </div>
        ) : (
          <div dir="ltr">
            <>
              <TableList
                thead={header.map((item) => (
                  <th id={item.id} key={item.id} >{item.title}</th>
                ))}
                tbody={
                  listAffiliate && listAffiliate.map((item) => (
                    <tr id={item.id} key={item.id}>
                      <th>{item.affiliate_client}</th>
                      <th>${parseInt(item.commission, 10)}</th>
                      <th>
                        {item.status === 1 && (
                          <h5>
                            <Badge
                              className="badge-status"
                              pill
                              color="primary"
                            >
                              Active
                            </Badge>
                          </h5>
                        )}
                        {item.status === 2 && (
                          <h5>
                            <Badge className="badge-status" pill color="danger">
                              Inactive
                            </Badge>
                          </h5>
                        )}
                      </th>
                    </tr>
                  ))
                }
              />
            </>
          </div>
        )}
      </Panel>
    );
  }
}

// AffiliateListClients.propTypes = {
//   listAffiliate: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number,
//       name: PropTypes.string,
//       commision: PropTypes.string,
//       status: PropTypes.number,
//     }),
//   ),
// };

AffiliateListClients.defaultProps = {
  listAffiliate: [],
};

const AffiliateListRedux = AffiliateListClients;

export { AffiliateListRedux as AffiliateListClients };
