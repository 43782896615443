import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TopbarSidebarButton from "./TopbarSidebarButton";
import { TopbarProfile } from "./TopbarProfile";
import TopDarkMode from "./TopbarDarkMode";

function Topbar({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  changeToDark,
  changeToLight,
  darkMode,
}) {
  return (
    <>
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to={`${process.env.REACT_APP_BASE_URL}affiliate`}><span className="topbar__title">Blankontech Partnership</span></Link>
          </div>
          <div className="topbar__right">
            <TopDarkMode
              darkMode={darkMode}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
            />
            <TopbarProfile />
          </div>
        </div>
      </div>
    </>
  );
}

// Topbar.propTypes = {
//   changeMobileSidebarVisibility: PropTypes.func.isRequired,
//   changeSidebarVisibility: PropTypes.func.isRequired,
//   changeToDark: PropTypes.func.isRequired,
//   changeToLight: PropTypes.func.isRequired,
//   darkMode: PropTypes.bool,
// };

Topbar.defaultProps = {
  darkMode: false,
};

export default Topbar;
