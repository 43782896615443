import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "reactstrap";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { faFileInvoice, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Panel, TableList } from "../../../shared/components";

const header = [
  { id: 1, title: "Amount" },
  { id: 2, title: "Project" },
  { id: 3, title: "Invoice" },
  { id: 4, title: "Invoice Date" },
  { id: 5, title: "Status" },
];

// eslint-disable-next-line react/prefer-stateless-function
class ResellerInvoiceList extends React.Component {
  render() {
    const { invoiceList } = this.props;
    return (
      <Panel
        xs={12}
        md={12}
        lg={12}
        title="Partner Clients Reseller Invoice"
        header
      >
        <div dir="ltr">
          {invoiceList.length === 0 ? (
            <div>
              <h3 style={{ justifyContent: "center", display: "flex" }}>
                <FontAwesomeIcon icon={faFileInvoice} />
              </h3>
              <p
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "12px",
                }}
              >
                You don&apos;t have any invoice
              </p>
            </div>
          ) : (
            <TableList
              thead={header.map((item) => (
                <th key={item.id}>{item.title}</th>
              ))}
              tbody={
                invoiceList && invoiceList.map((item) => (
                  <tr key={item.id}>
                    <th>${parseInt(item.amount, 10)}</th>
                    <th>{item.project_name}</th>
                    <th>
                      <span className="able__icon-invoice">
                        <a download href={item.file} rel="noreferrer" target="_blank">
                          <IconButton>
                            <FontAwesomeIcon
                              className="color__pdf"
                              icon={faFilePdf}
                            />
                          </IconButton>
                        </a>
                      </span>
                    </th>
                    <th>{item.invoice_date}</th>
                    <th>
                      {item.is_paid ? (
                        <h5>
                          <Badge className="badge-status" pill color="success">
                            Paid
                          </Badge>
                        </h5>
                      ) : (
                        <h5>
                          <Badge className="badge-status" pill color="danger">
                            Unpaid
                          </Badge>
                        </h5>
                      )}
                    </th>
                  </tr>
                ))
              }
            />
          )}
        </div>
      </Panel>
    );
  }
}

// ResellerInvoiceList.propTypes = {
//   invoiceList: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number,
//       amount: PropTypes.string,
//       file: PropTypes.node,
//       is_paid: PropTypes.number,
//     }),
//   ),
// };

ResellerInvoiceList.defaultProps = {
  invoiceList: [],
};

export default ResellerInvoiceList;
