

import React from "react";
import ArrowUpIcon from "mdi-react/ArrowUpIcon";
import { connect } from "react-redux";
import AccountCircleIcon from "mdi-react/AccountCircleIcon";
import ChartTimelineVariantIcon from "mdi-react/ChartTimelineVariantIcon";

import { Panel } from "../../../shared/components";
import ProfileMenu from "./Menu/MenuLinks/ProfileMenu";

class ProfileDetail extends React.Component {
  render() {
    const {
      user, children,
    } = this.props;

    return (
      <>
        <Panel xs={12} md={12} lg={12}>
          {/* <Panel xl={9} lg={8} md={12} xs={12}> */}
          {/* {this.props.isLoading ? (
           <Skeleton animation="wave" variant="circular" width={100} height={100} />
        ):( */}
          <>
            <div className="profile__information">
              <div className="profile__avatar">
                <div className="avatarProfile">

                  <AccountCircleIcon size="140px" />
                </div>
              </div>
              <div className="profile__data">
                <div className="profile__name">
                  {user.first_name}&nbsp; {user.last_name}
                </div>
                <div className="profile__flex__status">
                  <span className="profile__status">Monthly Income Expectations</span>
                </div>
                <div className="profile__project">
                  <div className="profile__border__projects">
                    <h4 className="profile__text__border_down">Amount</h4>
                    <div className="profile__flex_border_icon">
                      <span className="profile__border__icon__up">
                        <ArrowUpIcon />
                      </span>
                      <span className="profile__text_border">$ {user.total_active_income_affiliates_plus_reseller_projects}</span>
                    </div>
                  </div>
                  <div className="profile__border__projects">
                    <h4 className="profile__text__border_down">Projects</h4>
                    <div className="profile__flex_border_icon">
                      <span className="profile__border__icon__up">
                        {/* <ChartTimelineVariantIcon /> */}
                      </span>
                      <span className="profile__text_border">{user.total_active_projects}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ProfileMenu />
            </div>
          </>
          {/* )} */}
        </Panel>
        {/* <CardTos /> */}
        {children}
      </>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.userProfile;
  return { user };
}

const profileComponent = connect(
  mapStateToProps,
)(ProfileDetail);
export { profileComponent as ProfileDetail };
