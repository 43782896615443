import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ThemeProps, RTLProps } from "../../shared/prop-types/ReducerProps";

const MainWrapper = ({
  theme,
  children,
  rtl,
  location,
}) => {
  const direction = location.pathname === process.env.REACT_APP_BASE_URL ? "ltr" : rtl.direction;

  return (
    <Fragment>
      <div
        className={`${theme.className} ${direction}-support`}
        dir={direction}
      >
        <div className="wrapper">{children}</div>
      </div>
    </Fragment>
  );
};

// MainWrapper.propTypes = {
//   theme: ThemeProps.isRequired,
//   rtl: RTLProps.isRequired,
//   children: PropTypes.element.isRequired,
//   location: PropTypes.shape().isRequired,
// };

const mapStateToProps = (state) => ({
  theme: state.theme,
  rtl: state.rtl,
});

export default withRouter(connect(mapStateToProps)(MainWrapper));
