

import React from "react";
import { connect } from "react-redux";
import { Container, Row, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AffiliateAmount } from "../components/AffiliateAmount";
import { AffiliateListClients } from "../components/AffiliateListClients";
import { TransactionAccept } from "../components/TransactionAccept";
import { PanelWelcome } from "../../../shared/components/Panel";
import AffiliateToc from "./AffiliateToc";

const women1 = `${process.env.PUBLIC_URL}/img/flat_women_1.svg`;
const women2 = `${process.env.PUBLIC_URL}/img/flat_women_2.svg`;
const line = `${process.env.PUBLIC_URL}/img/line_2.svg`;
const line3 = `${process.env.PUBLIC_URL}/img/line-button.svg`;

// eslint-disable-next-line react/prefer-stateless-function
class AffiliateView extends React.Component {
  state = {
    toggle_panel_welcome: true,
  }

  handlePopover = () => {
    this.setState({
      toggle_panel_welcome: false,
    });
  };

  render() {

    const { items: { results }, payments, loading, loggedIn, user, open, affiliateDashboardData } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <>
            {loggedIn && this.state.toggle_panel_welcome && (
              <PanelWelcome xs={12} md={12} lg={12}>
                <div className="banner__welcome">
                  <button type="button" onClick={this.handlePopover} className="banner_close panel__btn">
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <img className="flat_women" src={women1} alt="" />
                  <div className="banner__text">
                    <h2 className="banner__title">Hi&nbsp;{user.first_name},&nbsp;Welcome to BlankonTech Partnership</h2>
                    <p className="text__detail">What&apos;s so special with our affiliate program?</p>
                    <p className="text__detail_you">You&apos;ll get passive income as long as your affiliation is working with us </p>
                    <p className="text__detail_you">details and information are here</p>
                    <div className="flex_button">
                      {/* <img className="flat_line" src={line3} alt="" /> */}
                      <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BLOG_URL}${process.env.REACT_APP_BLOG_PATH}`}>
                        <Button type="button" color="banner"> click here</Button>
                      </a>
                      {/* <img className="flat_line" src={line} alt="" /> */}
                    </div>
                  </div>
                  <img className="flat_women" src={women2} alt="" />
                </div>
              </PanelWelcome>
            )}
            <AffiliateToc />
          </>
          <AffiliateAmount affiliateDashboardData={affiliateDashboardData} />
          <TransactionAccept bodyHistory={payments} />
          <AffiliateListClients listAffiliate={results} isFetching={loading} />
        </Row>
      </Container>
    );
  }
}


function mapState(state) {
  const { items, payout, loading } = state.partnerAffiliate;
  const { loggedIn } = state.authentication;
  const { user } = state.userProfile;
  const { results } = payout;
  const payments = results;
  const affiliateDashboardData = state.affiliateDashboardFromConstant;
  return ({ items, payments, loading: items && loading, loggedIn, user, affiliateDashboardData });
}

const PageAffiliate = connect(mapState)(AffiliateView);
export { PageAffiliate as AffiliateView };