

import { userService } from "../utils/userUtils";
import { userConstants } from "../constanta/userContant";
import authFetch from "../helpers/authFetch";

function login(username, password) {
  const requestOptions = {
    method: "POST",
    body: { username, password },
  };
  return (dispatch) => {
    dispatch(request({ requestOptions }));
    return authFetch(`account/auth/`, requestOptions)
      .then((user) => {
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(success(user));
      });
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(first_name, last_name, email, password) {
  const requestOptions = {
    method: "POST",
    body: {
      first_name, last_name, email, password,
    },
  };
  return (dispatch) => {
    dispatch(request(requestOptions));
    return authFetch(`account/create/`, requestOptions)
      .then((user) => {
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(success(user));
      });
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
}

function postForgetPassword(email) {
  const requestOptions = {
    method: "POST",
    body: {
      email,
    },
  };
  return (dispatch) => {
    dispatch(request(requestOptions));
    return authFetch(`account/recover/password/request/`, requestOptions)
      .then((user) => {
        dispatch(success(user));
      });
  };

  function request(user) {
    return { type: userConstants.PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.PASSWORD_SUCCESS, user };
  }
}

function postResetPassword(email, token, password) {
  const requestOptions = {
    method: "POST",
    body: {
      email,
      token,
      password,
    },
  };
  return (dispatch) => {
    dispatch(request(requestOptions));
    return authFetch(`account/recover/password/reset/`, requestOptions)
      .then((user) => {
        dispatch(success(user));
      });
  };

  function request(user) {
    return { type: userConstants.RESET_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESET_PASSWORD_SUCCESS, user };
  }
}

export const userActions = {
  login,
  logout,
  postResetPassword,
  postForgetPassword,
  register,
};
