

import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import authFetch from "../../../redux/helpers/authFetch";
import AlertComponent from "../../../shared/components/Message/Alert";
import { userActions } from "../../../redux/actions/authActions";
import { ErrorResponse } from "../../../redux/helpers/response";

// type Props = {
//   location?: Object,
// };

// eslint-disable-next-line react/prefer-stateless-function
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.query = new URLSearchParams(props.location.search);
    this.state = {
      email: this.query.get("email"),
      token: this.query.get("token"),
      password: "",
      confirm_password: "",
      ErrorMessage: {},
      success_message: "",
      invalid_session: false,
      validating_session: true,
      loading: false,
    };
    this.onInputchange = this.onInputchange.bind(this);
    this.ResetPassword = this.ResetPassword.bind(this);
    this.CheckSession();
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  CheckSession() {
    authFetch(
      `account/recover/password/reset/?token=${this.state.token}&email=${this.state.email}`,
      {
        method: "GET",
      },
    )
      .then(() => {
        this.setState({
          validating_session: false,
        });
        return {};
      })
      .catch(({ json }) => {
        this.setState({
          validating_session: false,
          invalid_session: true,
          ErrorMessage: json,
        });
      });
  }

  ResetPassword(event) {
    event.preventDefault();
    this.setState({
      ErrorMessage: {},
      success_message: "",
      // loading: true,
    });
    if (!this.IsPasswordValid()) {
      return;
    }
    const { email, password, token } = this.state;
    this.props.resetForgetPassword(email, token, password)
      .then(() => {
        this.setState({
          success_message: "Password changed successfully, you can login with your new password ",
        });
      })
      .catch(({ json }) => {
        this.setState({
          ErrorMessage: json,
        });
      });
  }

  IsPasswordValid() {
    let StateUpdateDict = {};
    let IsPasswordValid = true;
    if (this.state.password === "") {
      IsPasswordValid = false;
      StateUpdateDict = {
        ErrorMessage: {
          password: "Password is not allowed to be empty",
        },
      };
    } else if (this.state.password !== this.state.confirm_password) {
      IsPasswordValid = false;
      StateUpdateDict = {
        ErrorMessage: {
          confirm_password: "Password is not match",
        },
      };
    }
    this.setState(
      StateUpdateDict,
    );
    return IsPasswordValid;
  }

  RenderResetPasswordForm() {
    if (!this.state.success_message && !this.state.invalid_session) {
      return (
        <form className="form" onSubmit={this.ResetPassword}>
          <div className="form__form-group">
            <span className="form__form-group-label">New Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onInputchange}
                  aria-describedby="emailHelp"
                  placeholder="Enter your new password"
                />
                {this.state.ErrorMessage.password ? (
                  <div
                    id="validationServer03Feedback"
                    className="form__form-group-error"
                  >
                    {this.state.ErrorMessage.password}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Confirm new password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <input
                  type="password"
                  id="confirm_password"
                  name="confirm_password"
                  value={this.state.confirm_password}
                  onChange={this.onInputchange}
                  aria-describedby="emailHelp"
                  placeholder="Enter your new password"
                />
                {this.state.ErrorMessage.confirm_password ? (
                  <div
                    id="validationServer03Feedback"
                    className="form__form-group-error"
                  >
                    {this.state.ErrorMessage.confirm_password}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <button
            className="btn btn-account account__btn account__btn--small"
            style={{ marginTop: "10px", color: "#fff" }}
            type="submit"
          >
            Reset Password
          </button>
        </form>
      );
    }
    return <span />;
  }

  render() {
    return (
      <div>
        {this.state.success_message ? (
          <>
            <AlertComponent color="success" className="alert--bordered" icon>
              {this.state.success_message}<br /><Link style={{ fontSize: "14px", fontWeight: "400" }} to={`${process.env.REACT_APP_BASE_URL}login`}>Login</Link>
            </AlertComponent>
          </>
        ) : (
          ""
        )}
        {this.state.ErrorMessage[0] ? (
          <div className="form__form-group-error" role="alert">
            {this.state.ErrorMessage[0]}
          </div>
        ) : (
          ''
        )}
        {this.RenderResetPasswordForm()}
      </div>
    );
  }
}

ResetPassword.defaultProps = {
  location: PropTypes.object.isRequired,
};

function mapState(state) {
  const { loggedIn, isLoading } = state.authentication;
  return { loggedIn, isLoading };
}

const actionCreators = {
  resetForgetPassword: userActions.postResetPassword,
};

export default withRouter(
  connect(mapState, actionCreators)(ResetPassword),
);

// export default ResetPassword;
