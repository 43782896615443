

import React from "react";
import { connect } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";
import EyeIcon from "mdi-react/EyeIcon";
import CircularProgress from "@mui/material/CircularProgress";
import { userActions } from "../../../redux/actions/authActions";
import { ErrorResponse } from "../../../redux/helpers/response";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      ErrorMessage: {},
      showPassword: false,
      submitted: false,
      isLoading: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, ErrorMessage: "" });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      first_name, last_name, email, password,
    } = this.state;
    this.setState({
      isLoading: true,
      submitted: false,
    });
    this.props
      .register(first_name, last_name, email, password)
      .then(() => {
        this.setState({
          isLoading: false,
          submitted: true,
        });
      })
      .catch((error) => {
        const { json } = error;
        let ErrorMessage = "";
        if (!json) {
          ErrorMessage = "Something went wrong.";
        } else if (json.nonFieldErrors) {
          ErrorMessage = json.nonFieldErrors;
        } else {
          ErrorMessage = json;
        }
        this.setState({
          ErrorMessage: ErrorMessage,
          isLoading: false,
          submitted: false,
        });
      });
  };

  handleShowPassword = () => {
    this.setState((prev) => ({
      showPassword: !prev.showPassword,
    }));
  };

  render() {
    const {
      first_name, last_name, email, password, showPassword, submitted,
    } = this.state;
    if (this.state.submitted) {
      return <Redirect to={`${process.env.REACT_APP_BASE_URL}login`} />;
    }
    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <div className="form__form-group-flex">
            <div
              className="form__form-group-width"
              style={{ marginRight: "10px" }}
            >
              <span className="form__form-group-label">First name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="First Name (required)"
                    name="first_name"
                    onChange={this.handleChange}
                    value={first_name}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group-width">
              <span className="form__form-group-label">Last name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Last Name (required)"
                    name="last_name"
                    onChange={this.handleChange}
                    value={last_name}
                    required
                  />
                  {/* {submitted && !last_name && <div className="form__form-group-error">This field may not be blank.</div>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-input-wrap">
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={this.handleChange}
                value={email}
                required
              />
              {this.state.ErrorMessage.email ? (
                <div className="form__form-group-error">
                  {this.state.ErrorMessage.email[0]}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-input-wrap">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                minLength="5"
                onChange={this.handleChange}
                value={password}
                name="password"
                required
              />
              <button
                type="button"
                className={`form__form-group-button${showPassword ? " active" : ""
                  }`}
                onClick={this.handleShowPassword}
              >
                <EyeIcon />
              </button>
              {this.state.ErrorMessage.password ? (
                <div className="form__form-group-error">
                  {this.state.ErrorMessage.password[0]}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <button
          className="btn btn-account account__btn account__btn--small"
          style={{ marginTop: "10px", color: "#fff", width: "100%" }}
          type="submit"
        >
          Sign Up
        </button>
        {this.state.isLoading ? (
          <div style={{ marginTop: "12px" }}>
            <CircularProgress />
          </div>
        ) : (
          <Link
            className="btn btn-outline-primary account__btn account__btn--small"
            style={{ width: "100%" }}
            to={`${process.env.REACT_APP_BASE_URL}login`}
          >
            Login
          </Link>
        )}
      </form>
    );
  }
}

function mapState(state) {
  const { registering, isLoading } = state.registration;
  return { registering, isLoading };
}

const actionCreators = {
  register: userActions.register,
};

const connectedRegisterPage = withRouter(
  connect(mapState, actionCreators)(RegisterForm),
);
export { connectedRegisterPage as RegisterForm };
