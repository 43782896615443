import React from "react";
import ProfileLink from "./ProfileLinks";

function MenuContent() {
  return (
    <div className="sidebar__content">
      <span className="profile__block">
        <ProfileLink
          title="Overview"
          route={`${process.env.REACT_APP_BASE_URL}overview`}
        />
        <ProfileLink
          title="Settings"
          route={`${process.env.REACT_APP_BASE_URL}edit-profile`}
        />
        <ProfileLink
          title="Payout"
          route={`${process.env.REACT_APP_BASE_URL}payment`}
        />
      </span>
    </div>
  );
}

export default MenuContent;
