

import React from "react";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaCheckCircle } from "react-icons/fa";

// type Props = {
//     handleModalHide: () => void,
//     handleDeletePayment: () => void,
//     toggle: boolean,
// };
function ModalSuccess({
  message,
  toggle,
  closeModal
}) {

  return (
    <>
      <Modal
        isOpen={toggle}
        className="modal-dialog--header modal-dialog--payment"
        centered
        toggle={closeModal}
      >
        <div className="modal__header">
          <h5 className="text-modal modal__title">
            <div className="modal__border-success">
              <FaCheckCircle
                className="card-success-modal"
              />
            </div>
          </h5>
          <h4 className="text-modal modal__title" style={{ marginTop: "20px" }}>
            {message}
          </h4>
        </div>
        <div className="modal__body">
          <Button
            type="submit"
            onClick={closeModal}
            style={{ marginRight: "8px" }}
            className="btn btn-cancel payment__btn payment__btn--small"
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

function ModalWarning({
  toggle,
  message,
  closeModal,
}) {
  return (
    <>
      <Modal
        isOpen={toggle}
        className="modal-dialog--header modal-dialog--payment"
        centered
        toggle={closeModal}
      >
        <div className="modal__header">
          <h5 className="text-modal modal__title">
            <div className="modal__border-payment-warning">
              <FontAwesomeIcon
                className="card-warning-modal"
                icon={faExclamation}
              />
            </div>
          </h5>
          <h4 className="text-modal modal__title" style={{ marginTop: "20px" }}>
            {message}
          </h4>
        </div>
        <div className="modal__body">
          <Button
            type="submit"
            onClick={closeModal}
            style={{ marginRight: "8px" }}
            className="btn btn-cancel payment__btn payment__btn--small"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}

export { ModalSuccess, ModalWarning };
