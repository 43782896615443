import React from "react";
import { Link } from "react-router-dom";
import { LayoutAuth } from "../../../shared/components/LayoutAuth";
import { LoginForm } from "../components/LogInForm";

const blankon = `${process.env.PUBLIC_URL}/img/favblannkon.webp`;

// eslint-disable-next-line react/prefer-stateless-function
function LoginPage() {
  return (
    <LayoutAuth>
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title account__margin">
              Login to partnership platform
            </h3>
            <img className="account__head-image" src={blankon} alt="" />
          </div>
          <LoginForm />
        </div>
        <span className="footer__link">
          <Link className="footer__link-term" to={`${process.env.REACT_APP_BASE_URL}terms-condition`} >Terms and Condition</Link>
          <p>Copyright &copy; {new Date().getFullYear()}</p>
        </span>
      </div>
    </LayoutAuth>
  );
}

export default LoginPage;