

import React, { Fragment } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import { Routers } from "./Router";
import ScrollToTop from "./ScrollToTop";
import { profileActions } from "../../redux/actions/userAuth";
import { AffiliateActions } from "../../redux/actions/AffiliateActions";
import { resellerActions } from "../../redux/actions/resellerActions";
import { paymentActions } from "../../redux/actions/paymentActions";
import CacheBuster from "../../CacheBuster";

// type State = {
//   isLoading: boolean,
//   isLoaded: boolean,
//   isAuth: boolean,
// };

class App extends React.Component {
  state = {
    isLoading: true,
    isLoaded: false,
    isAuth: true,
  };

  componentDidMount() {
    window.addEventListener("load", this.onLoaded);
    setTimeout(() => {
      this.getFetchApi(true);
    }, 300);
  }

  componentWillUnmount() {
    this.getFetchApi();
  }

  getFetchApi = (value) => {
    return new Promise((resolve) => {
      if (this.props.loggedIn) {
        this.props.getUser();
        this.props.affiliateClient();
        this.props.resellerlient();
        this.props.invoiceList();
        this.props.affiliate();
        this.props.listPayment();
        this.props.affiliateDashboard();
        resolve({ success: true });
      }
    });
  };

  onLoaded = () => {
    this.setState(
      { isLoading: false },
      () => {
        setTimeout(() => {
          this.setState({ isLoaded: true });
        }, 500);
      },
      [],
    );
  };

  render() {
    const { isLoaded, isLoading } = this.state;
    if (this.props.user === null) {
      return (
        <div className={`load${this.props.loggedIn ? "" : " loaded"}`}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path
                fill="#4ce1b6"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      );
    }
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <ScrollToTop>
              <Fragment>
                {!isLoaded && (
                  <div className={`load${isLoading ? "" : " loaded"}`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path
                          fill="#4ce1b6"
                          d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                <div>
                  <Routers />
                </div>
              </Fragment>
            </ScrollToTop>
          );
        }}
      </CacheBuster>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { loggedIn, user } = authentication;
  return { loggedIn, user };
}

const actionCreators = {
  getUser: profileActions.getUser,
  affiliateClient: AffiliateActions.affiliatesList,
  affiliate: AffiliateActions.affiliatesPayout,
  resellerlient: resellerActions.resellerList,
  invoiceList: resellerActions.invoiceList,
  listPayment: paymentActions.listPaymentMethod,
  affiliateDashboard: AffiliateActions.getAffiliatesDashboard,
};

export default connect(mapStateToProps, actionCreators)(App);
