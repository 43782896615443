

import { React, useState, useEffect } from "react";
import {
  faEdit,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteIcon from "mdi-react/DeleteIcon";

// type Props = {
//   delete_icon,
//   edit_icon,
//   detail_icon,
//   getModal: () => void,
//   updateModal: () => void,
//   editModal: () => void,
// };

function IconModal({ delete_icon, edit_icon, detail_icon, getModal, updateModal, editModal }) {
  return (
    <>
      <FontAwesomeIcon
        className="card-mastercard"
        icon={faEye}
        style={{
          marginRight: "12px",
          fontSize: "12px",
          outline: "none",
        }}
        id={`detail-${detail_icon}`}
        onClick={getModal}
      />
      <FontAwesomeIcon
        className="card-mastercard"
        icon={faEdit}
        id={`edit-${edit_icon}`}
        onClick={updateModal}
        style={{ "outline": "none" }}
      />
      <DeleteIcon
        className="pencil__icon"
        id={`delete-${delete_icon}`}
        onClick={editModal}
        style={{ "outline": "none" }}
      />

      {/* <UncontrolledTooltip placement="right" target={`delete-${delete_icon}`}>
        Delete Payment
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="top" target={`detail-${detail_icon}`}>
        Detail Payment
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="right" target={`edit-${edit_icon}`}>
        Edit Payment
      </UncontrolledTooltip> */}
    </>
  );
}

export {
  IconModal,
};
