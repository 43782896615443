import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';

function Layout({ dispatch, sidebar }) {
  const [darkMode, setDarkMode] = useState(() => {
    const ThemeDark = localStorage.getItem('theme');
    // if showBanner null/undefined fallback to true
    return JSON.parse(ThemeDark) === "theme-dark" ? true : false;
  });
  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
  });

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    setDarkMode((prevState) => !prevState);
    localStorage.setItem("theme", JSON.stringify("theme-dark"));
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    setDarkMode((prevState) => !prevState);
    localStorage.setItem("theme", JSON.stringify("theme-light"));
    dispatch(changeThemeToLight());
  };
  return (
    <div className={layoutClass}>
      <Topbar
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        changeSidebarVisibility={sidebarVisibility}
        changeToDark={changeToDark}
        changeToLight={changeToLight}
        darkMode={darkMode}
      />
      <Sidebar
        sidebar={sidebar}
        changeMobileSidebarVisibility={mobileSidebarVisibility}
      />
    </div>
  );
}

// Layout.propTypes = {
//   dispatch: PropTypes.func.isRequired,
//   sidebar: SidebarProps.isRequired,
// };

export default withRouter(connect((state) => ({
  sidebar: state.sidebar,
}))(Layout));
