

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BiLineChart, BiLineChartDown } from "react-icons/bi";
import { MdTrendingDown } from "react-icons/md";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBalanceScale, faChartLine, faDollarSign, faMoneyBill, faRocket, faArrowCircleUp, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";

import { PanelCommision, PanelCompare, PanelPayout } from "../../../shared/components/Panel";

// type State = {
//   open: boolean,
//   paypal: boolean,
//   status,
// };
// fontawesome.library.add(fas, fad);

// eslint-disable-next-line react/prefer-stateless-function
class AffiliateAmount extends React.Component {
  state = {
    open: false,
    paypal: false,
    status: 0,
  };


  render() {
    const {
      affiliateDashboardData
    } = this.props;
    let ShowCompareArrow;

    let totalComparationCurrentMonthAndPreviousMonth = "loading...";
    let lastPayoutCurrentMonth = "loading...";
    let total_expected_commission_affiliate_clients_current_month = "loading...";

    if (affiliateDashboardData.loading === false) {
      const isComparationNotAvailable = affiliateDashboardData.items.comparation_not_available;
      totalComparationCurrentMonthAndPreviousMonth = affiliateDashboardData.items.total_comparation_commission_current_month_and_previous_month;

      if (isComparationNotAvailable === false && totalComparationCurrentMonthAndPreviousMonth > 0) {
        if (affiliateDashboardData.items.is_comparation_commission_current_month_less_than_previous_month === true) {
          ShowCompareArrow = "down";
        } else if (affiliateDashboardData.items.is_comparation_commission_current_month_less_than_previous_month === false) {
          ShowCompareArrow = "up";
        }
      }


      lastPayoutCurrentMonth = affiliateDashboardData.items.total_affiliate_payout_amount_current_month;
      total_expected_commission_affiliate_clients_current_month = affiliateDashboardData.items.total_expected_commission_affiliate_clients_current_month;

    }

    return (
      <>
        <PanelCommision md={12} xl={3} lg={6} xs={12} title="Expected Commission" header>
          <div dir="ltr">
            <div className="dashboard__weight-stats">
              <div className="card-affiliate-Money">
                <FontAwesomeIcon className="card-money" icon={faMoneyBill} />
              </div>
              {/* <FontAwesomeIcon className="card-dollar" icon={faChartLine} /> */}
            </div>
            <div className="card-panel-amount">
              <FontAwesomeIcon style={{ marginRight: "12px" }} icon={faDollarSign} />
              <h3 className="dashboard__weight-stat-title card-panel-amount-text">{total_expected_commission_affiliate_clients_current_month} </h3>
            </div>
          </div>
        </PanelCommision>
        <PanelPayout
          md={12}
          xl={3}
          lg={6}
          xs={12}
          title="Current Month Payout"
          header
        >
          <div dir="ltr">
            <div className="dashboard__weight-stats">
              <div className="card-affiliate-payout">
                <FontAwesomeIcon className="card-rocket" icon={faRocket} />
              </div>
              {/* <FontAwesomeIcon className="card-dollar" icon={faChartLine} /> */}
            </div>
            <div className="card-panel-amount">
              <FontAwesomeIcon style={{ marginRight: "12px" }} icon={faDollarSign} />
              <h3 className="dashboard__weight-stat-title card-panel-amount-text">{lastPayoutCurrentMonth} </h3>
            </div>
          </div>
        </PanelPayout>
        <PanelCompare md={12} xl={3} lg={6} xs={12} title="month over month commission" header>
          <div dir="ltr">
            <div className="dashboard__weight-stats">
              <div className="card-affiliate-compare">

                <FontAwesomeIcon className="card-compare" icon={faBalanceScale} />

              </div>
              {/* <FontAwesomeIcon className="card-dollar" icon={faChartLine} /> */}
            </div>
            <div className="card-panel-amount">
              <FontAwesomeIcon style={{ marginRight: "12px" }} icon={faDollarSign} />&nbsp;
              <h3 className="dashboard__weight-stat-title card-panel-amount-text">{totalComparationCurrentMonthAndPreviousMonth} {ShowCompareArrow === "down" ? <FaChevronCircleDown color="#f7401b" size="15" /> : (ShowCompareArrow === "up" ? <FaChevronCircleUp color="rgb(24, 177, 130)" size="15" /> : " ")}</h3>

              {/* &nbsp;({isNaN(compare) ? 0 : compare}&nbsp;<FontAwesomeIcon icon={faPercent} />) */}
            </div>
          </div>
        </PanelCompare>
      </>
    );
  }
}

const AffiliateProjectRedux = AffiliateAmount;
export { AffiliateProjectRedux as AffiliateAmount };
