import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { paymentActions } from "../../../redux/actions/paymentActions";
import ModalDetailPayment from "./ModalDetailPayment";
import ModalFormPayment from "./ModalMenagementPayment";
import ModalFormBank from "./ModalFormBank";
import ModalFormPayPal from "./ModalFormPaypal";
import { IconModal } from "../../../shared/components/IconModal";
import { ModalDelete } from "../../../shared/components/ModalDelete";
import {
  ModalSuccess,
  ModalWarning,
} from "../../../shared/components/ModalAllert";
import CardPayPal from "./CardPayPal";
import CardBank from "./CardBank";
import { maxLengthCheck, Slice } from "../../../shared/helpers";

// eslint-disable-next-line react/prefer-stateless-function
class PaymentList extends React.Component {
  state = {
    paypal: { email: "" },
    bankPayments: {
      bank_currency: "",
      bank_name: "",
      routing_number: "",
      account_number: "",
      name: "",
      bic: "",
      iban: "",
    },
    currency: [
      {
        name: "USD",
        value: "USD",
      },
      {
        name: "EURO",
        value: "EURO",
      },
    ],
    selectedValue: "USD",
    showModal: 0,
    usd: "usd",
    euro: "euro",
    toggle: 0,
    type: {
      bank: 1,
      paypal: 2,
    },
    modal_success: {
      message: null,
      toggle: false,
    },
    modal_warning: {
      message: null,
      toggle: false,
    },
    updatePayment: 0,
    submitted2: false,
    ErrorMessage: {},
    error: {},
    popover: false,
    submitted: false,
  };

  componentDidMount() {
    this.props.getPayment();
    // this.handleModalUpdate();
  }

  selectedValueHandler = (e) => {
    this.setState({ selectedValue: e.target.value });
  };

  handleChangePayPal = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      paypal: {
        ...prevState.paypal,
        [name]: value,
      },
    }));
  };

  handleChangeBank = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      bankPayments: {
        ...prevState.bankPayments,
        [name]: value,
      },
    }));
  };

  getModal(id) {
    return this.setState({ showModal: id });
  }

  hideModal(id) {
    return this.setState({ showModal: 0 });
  }

  handleModal(id) {
    return this.setState({ toggle: id });
  }

  handleModalUpdate(id, data) {
    if (data.usd) {
      this.setState({
        updatePayment: id,
        bankPayments: {
          bank_name: data.bank_name,
          routing_number: data.routing_number,
          account_number: data.account_number,
          bic: "",
          name: data.name,
          iban: "",
        },
        paypal: {
          email: data.email,
        },
        selectedValue: "USD",
        currency: [
          {
            name: "USD",
            value: "USD",
          },
          {
            name: "EURO",
            value: "EURO",
          },
        ],
      });
    } else {
      this.setState({
        updatePayment: id,
        bankPayments: {
          bank_name: data.bank_name,
          routing_number: "",
          account_number: "",
          bic: data.bic,
          name: data.name,
          iban: data.iban,
        },
        selectedValue: "EURO",
        currency: [
          {
            name: "EURO",
            value: "EURO",
          },
          {
            name: "USD",
            value: "USD",
          },
        ],
        paypal: {
          email: data.email,
        },
      });
    }
  }

  handleCloseUpdate(id) {
    return this.setState({ updatePayment: 0 });
  }

  handleModalHide(id) {
    return this.setState({ toggle: 0 });
  }

  handlePopover = () => {
    this.setState((prevState) => ({
      popover: !prevState.popover,
    }));
  };

  clearErrorState = () => {
    this.setState({ ErrorMessage: {}, error: {}, deleteError: null });
  };
  clearSubmitState = () => {
    this.setState({
      submitted: false,
      submitted2: false,
      open: false,
      updatePayment: 0,
    });
  };

  handleSubmitBank = (id) => async (event) => {
    event.preventDefault();
    const { bankPayments, type, usd, euro, selectedValue } = this.state;
    this.setState({ submitted: true });
    this.clearErrorState();
    bankPayments.bank_currency = selectedValue;
    let bankValues = {};
    let is_bank_input_valid = false;
    if (
      selectedValue === "USD" &&
      bankPayments.routing_number &&
      bankPayments.bank_name &&
      bankPayments.account_number &&
      bankPayments.name
    ) {
      const banksUsd = Slice(bankPayments, [
        "bank_name",
        "account_number",
        "name",
        "routing_number",
        "bank_currency",
      ]);
      bankValues = { ...banksUsd, usd };
      is_bank_input_valid = true;
    } else if (
      selectedValue === "EURO" &&
      bankPayments.bic &&
      bankPayments.bank_name &&
      bankPayments.iban &&
      bankPayments.name
    ) {
      const banksEuro = Slice(bankPayments, [
        "bank_name",
        "iban",
        "name",
        "bic",
        "bank_currency",
      ]);
      bankValues = { ...banksEuro, euro };
      is_bank_input_valid = true;
    }
    if (!is_bank_input_valid) {
      return;
    }
    await this.props.updatePayment(bankValues, type.bank, id);

    let error = this.props.error;
    if (error) {
      this.handleInlineError("data", "bank", error);
    } else {
      this.clearErrorState();
      this.setState({
        open: false,
        submitted: false,
        updatePayment: 0,
      });
      this.handleShowModalSuccess(
        "Your payment method has been updated successfully"
      );
    }
  };

  handleCloseModalSuccess = () => {
    this.setState({
      modal_success: {
        message: null,
        toggle: false,
      },
    });
  };

  handleShowModalSuccess = (message) => {
    this.setState({
      modal_success: {
        message: message,
        toggle: true,
      },
    });
  };

  handleCloseModalWarning = () => {
    this.setState({
      modal_warning: {
        message: null,
        toggle: false,
      },
    });
  };

  showModalWarning = (message) => {
    this.setState({
      modal_warning: {
        message: message,
        toggle: true,
      },
      deleteError: null,
    });
  };

  handleChangeSubmit = (id) => async (event) => {
    event.preventDefault();
    const { paypal, type } = this.state;
    this.setState({ submitted2: true });
    if (!this.isValidEmail()) {
      this.setState({
        ErrorMessage: {
          email: "This is not a valid email",
        },
      });
      return;
    }
    if (paypal.email) {
      await this.props.updatePayment(paypal, type.paypal, id);
      let error = this.props.error;
      if (error) {
        this.handleInlineError("email", "check_email", error);
        return;
      }
      this.handleShowModalSuccess(
        "Your payment method has been updated successfully"
      );
      this.setState({
        updatePayment: 0,
      });
    }
  };

  handleModalError = (messageKey, errorResponse) => {
    if (errorResponse && "json" in errorResponse) {
      let errorMessage = errorResponse.json[messageKey];
      if ("data" in errorResponse.json && errorMessage) {
        this.showModalWarning(errorMessage);
      } else {
        this.showModalWarning(
          "OOPS! Something went wrong. Please try again or contact customer support"
        );
      }
    }
  };

  handleInlineError = (messageKey, errorKey, errorResponse = {}) => {
    if (errorResponse && "json" in errorResponse) {
      let errorMessage = errorResponse.json[messageKey];

      if (messageKey in errorResponse.json) {
        this.setState({
          ErrorMessage: { [errorKey]: errorMessage },
        });
      } else {
        this.clearSubmitState();
        this.clearErrorState();
        this.showModalWarning(
          "OOPS! Something went wrong. Please try again or contact customer support"
        );
      }
    }
  };

  handleDeletePayment = async (id) => {
    this.setState({ toggle: 0 });
    await this.props.deletePayment(id);
    let deleteError = this.props.deleteError;
    this.handleModalError("data", deleteError);
  };

  isValidEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.paypal.email).toLowerCase());
  }

  render() {
    const {
      showModal,
      toggle,
      bankPayments: {
        name,
        bank_name,
        bic,
        iban,
        routing_number,
        account_number,
      },
      paypal: { email },
      submitted,
      currency,
      submitted2,
      type,
    } = this.state;
    const { items } = this.props;
    return (
      <>
        <div className="row">
          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <div style={{ paddingRight: "8px" }} key={item.id}>
                <div>
                  {item.type === type.bank && item.type !== "" ? (
                    <>
                      <CardBank
                        card_numer={item.data.account_number || item.data.iban}
                        bank_name={item.data.bank_name}
                        name={item.data.name}
                        currency={
                          item.data.usd === "usd" ? <>usd</> : <>euro</>
                        }
                      >
                        <IconModal
                          getModal={() => this.getModal(item.id)}
                          updateModal={() =>
                            this.handleModalUpdate(item.id, item.data)
                          }
                          editModal={() => this.handleModal(item.id)}
                          delete_icon={item.id}
                          edit_icon={item.id}
                          detail_icon={item.id}
                        />
                      </CardBank>
                    </>
                  ) : (
                    ""
                  )}
                  {item.type === type.paypal && item.type !== "" ? (
                    <>
                      <CardPayPal email={item.data.email}>
                        <IconModal
                          getModal={() => this.getModal(item.id)}
                          updateModal={() =>
                            this.handleModalUpdate(item.id, item.data)
                          }
                          editModal={() => this.handleModal(item.id)}
                          delete_icon={item.id}
                          edit_icon={item.id}
                          detail_icon={item.id}
                        />
                      </CardPayPal>
                    </>
                  ) : (
                    ""
                  )}
                  {item.deleting ? (
                    <div
                      style={{ marginTop: "20px" }}
                      className="card-payment__loading-delete"
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      <ModalDelete
                        toggle={toggle === item.id}
                        handleModalHide={() => this.handleModalHide(item.id)}
                        handleDeletePayment={() =>
                          this.handleDeletePayment(item.id)
                        }
                      />
                    </>
                  )}

                  <ModalFormPayment
                    toggle={this.state.updatePayment === item.id}
                    handleModal={() => this.handleCloseUpdate(item.id)}
                    onSubmit={
                      item.type === 1
                        ? this.handleSubmitBank(item.id)
                        : this.handleChangeSubmit(item.id)
                    }
                    type={item.type}
                    updateYourData
                    addPayment={false}
                  >
                    {item.type === type.bank && item.type !== "" ? (
                      <>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Currency
                          </span>
                          <div className="form__form-group-select">
                            <div className="form__form-group-input-wrap">
                              <select
                                id="searchSelect"
                                name="searchSelect"
                                onChange={this.selectedValueHandler}
                                className="form__select"
                              >
                                {currency.map((item) => (
                                  <option key={item.value}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <ModalFormBank
                          submitted={submitted}
                          selectedValue={this.state.selectedValue}
                          maxLength={maxLengthCheck}
                          onChange={(bank) => this.setState(bank)}
                          errors={
                            this.state.ErrorMessage.bank && (
                              <div
                                id="validationServer03Feedback"
                                className="form__form-group-error"
                              >
                                {this.state.ErrorMessage.bank}
                              </div>
                            )
                          }
                          errorsEuro={
                            this.state.ErrorMessage.bankEuro && (
                              <div
                                id="validationServer03Feedback"
                                className="form__form-group-error"
                              >
                                {this.state.ErrorMessage.bankEuro}
                              </div>
                            )
                          }
                          bankPayments={{
                            bank_name,
                            name,
                            iban,
                            bic,
                            routing_number,
                            account_number,
                          }}
                        />
                        <button
                          className="btn btn-account account__btn account__btn--small"
                          style={{
                            marginTop: "10px",
                            width: "100%",
                            color: "#fff",
                            position: "relative",
                          }}
                          type="submit"
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      <>
                        <ModalFormPayPal
                          submitted={submitted2}
                          paypal={{ email }}
                          onChange={(paypal) => this.setState(paypal)}
                        />
                        {this.state.ErrorMessage.check_email && (
                          <div
                            id="validationServer03Feedback"
                            className="form__form-group-error"
                          >
                            {this.state.ErrorMessage.check_email}
                          </div>
                        )}
                        <button
                          className="btn btn-account account__btn account__btn--small"
                          style={{
                            marginTop: "10px",
                            color: "#fff",
                            position: "relative",
                            width: "100%",
                          }}
                          type="submit"
                        >
                          Save
                        </button>
                      </>
                    )}
                  </ModalFormPayment>
                </div>
                <ModalDetailPayment
                  toggle={showModal === item.id}
                  handleModal={() => this.hideModal(item.id)}
                  type={item.type}
                  usd={item.data.usd}
                  bank_name={item.data.bank_name}
                  routing_number={item.data.routing_number}
                  account_number={item.data.account_number}
                  iban={item.data.iban}
                  email={item.data.email}
                  bic={item.data.bic}
                  name={item.data.name}
                />
              </div>
            ))}
          <ModalSuccess
            toggle={this.state.modal_success.toggle}
            message={this.state.modal_success.message}
            closeModal={() => this.handleCloseModalSuccess()}
          ></ModalSuccess>
          <ModalWarning
            toggle={this.state.modal_warning.toggle}
            message={this.state.modal_warning.message}
            closeModal={() => this.handleCloseModalWarning()}
          ></ModalWarning>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { items, loading, update, deleteError, error } = state.paymentMethod;
  return {
    items,
    loading,
    update,
    deleteError,
    error,
  };
}

const actionCreators = {
  deletePayment: paymentActions.deletePaymentMethod,
  getPayment: paymentActions.listPaymentMethod,
  updatePayment: paymentActions.updatePaymentMethod,
};

const Payment = connect(mapState, actionCreators)(PaymentList);

export { Payment as PaymentList };
