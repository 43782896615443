import { AffiliateConstants } from "../constanta/AffiliateContant";

const initialState = {
    items: [],
    payout: [],
    loading: false,
};

export default function affiliateDashboardFromConstant(state = initialState, action) {
    switch (action.type) {
        case AffiliateConstants.AFFILIATE_DASHBOARD_REQUEST:
            return {
                loading: true,
            };
        case AffiliateConstants.AFFILIATE_DASHBOARD_SUCCESS:
            return {
                loading: false,
                items: action.affiliateDashboard,
            };
        case AffiliateConstants.AFFILIATE_DASHBOARD_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}