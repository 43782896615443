

import { authHeader } from "../helpers/authHeader";
import { API_URL, handleResponse } from "../helpers/handleResponse";

function logout() {
  localStorage.removeItem("user");
}

export const userService = {
  logout,
};
