import { PaymentConstants } from "../constanta/PaymentContant";

const initialState = {
  items: [],
  loading: false,
  error: null,
  update: false,
  deleteError: null,
};

export default function paymentMethod(state = initialState, action) {
  switch (action.type) {
    case PaymentConstants.PAYMENT_REQUEST:
      return {
        ...state,
        items: state.items,
        loading: true,
        error: null,
      };
    case PaymentConstants.PAYMENT_SUCCESS:
      return {
        ...state,
        items: state.items.concat(action.payment),
        error: null,
        loading: false,
      };
    case PaymentConstants.PAYMENT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case PaymentConstants.PAYMENTS_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        items: state.items.map((payment) => payment.id === action.payment.id ? action.payment : payment),
        update: true,
      };
    case PaymentConstants.PAYMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        items: state.items.map((payment) => payment.id === action.payment.id ? action.payment : payment),
        update: false,
      };
    case PaymentConstants.PAYMENTS_UPDATE_FAILURE:
      return {
        ...state,
        items: state.items.map((payment) => {
          if (payment.id === action.id) {
            const { update, ...paymentCopy } = payment;
            return { ...paymentCopy, updateError: action.error };
          }
          return payment;
        }),
        error: action.error,
      };
    case PaymentConstants.PAYMENTS_REQUEST:
      return {
        ...state,
        items: state.items,
      };
    case PaymentConstants.PAYMENTS_SUCCESS:
      return {
        ...state,
        items: action.listPayment.results,
      };
    case PaymentConstants.PAYMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        items: action.error,
      };
    case PaymentConstants.DELETE_REQUEST:
      return {
        ...state,
        deleteError: null,
        items: state.items.map((payment) => payment.id === action.id ? { ...payment, deleting: true } : payment),
      };
    case PaymentConstants.DELETE_SUCCESS:
      return {
        ...state,
        deleteError: null,
        items: state.items.filter((payment) => payment.id !== action.id),
      };
    case PaymentConstants.DELETE_FAILURE:
      return {
        ...state,
        deleteError: action.error,
        error: action.error,
        waiting: false,
        items: state.items.map((payment) => {
          if (payment.id === action.id) {
            const { deleting, ...paymentCopy } = payment;
            return { ...paymentCopy, deleteError: action.error };
          }
          return payment;
        }),
      };
    default:
      return state;
  }
}
