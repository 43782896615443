export const PaymentConstants = {
    PAYMENT_REQUEST: 'PAYMENT_CREATE_REQUEST',
    PAYMENT_SUCCESS: 'PAYMENT_CREATE_SUCCESS',
    PAYMENT_FAILURE: 'PAYMENT_CREATE_FAILURE',

    PAYMENTS_REQUEST: 'PAYMENTS_LIST_REQUEST',
    PAYMENTS_SUCCESS: 'PAYMENTS_LIST_SUCCESS',
    PAYMENTS_FAILURE: 'PAYMENTS_LIST_FAILURE',

    PAYMENTS_UPDATE_REQUEST: 'PAYMENTS_UPDATE_REQUEST',
    PAYMENTS_UPDATE_SUCCESS: 'PAYMENTS_UPDATE_SUCCESS',
    PAYMENTS_UPDATE_FAILURE: 'PAYMENTS_UPDATE_FAILURE',

    DELETE_REQUEST: 'DELETE_PAYMENTS_REQUEST',
    DELETE_SUCCESS: 'DELETE_PAYMENTS_SUCCESS',
    DELETE_FAILURE: 'DELETE_PAYMENTS_FAILURE',
};