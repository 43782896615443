import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faUniversity, faWallet } from "@fortawesome/free-solid-svg-icons";

// type Props = {
//   type,
//   toggle: boolean,
//   handleModal: () => void,
//   onSubmit: () => void,
//   handleMasterCard: () => void,
//   handlePayPal: () => void,
//   dropdown: boolean,
//   handleDropdown: () => void,
//   addPayment: boolean,
//   children: React.Node,
//   updateYourData: boolean,
// };

function ModalMenagementPayment({
  type,
  toggle,
  addPayment,
  handleModal,
  onSubmit,
  dropdown,
  handleDropdown,
  updateYourData,
  handleMasterCard,
  handlePayPal,
  children,
}) {
  return (
    <Modal
      centered
      isOpen={toggle}
      toggle={handleModal}
      className="modal-dialog--header modal-dialog--payment"
    >
      <div className="modal__header">
        <h5 className="text-modal modal__title">
          {addPayment && (
            <div className="modal__border-payment-paypal">
              <FontAwesomeIcon className="card-payPal-modal" icon={faWallet} />
            </div>
          )}
          {type === 2 && (
            <div className="modal__border-payment-paypal">
              <FontAwesomeIcon className="card-payPal-modal" icon={faPaypal} />
            </div>
          )}
          {type === 1 && (
            <div className="modal__border-payment-paypal">
              <FontAwesomeIcon
                className="card-Bank-modal"
                icon={faUniversity}
              />
            </div>
          )}
        </h5>
      </div>
      <div className="modal__body">
        {addPayment && (
          <>
            <h4 style={{ margin: "20px 0px" }}>Add payout method</h4>
            <p>you must have deposited payout method</p>
            <Dropdown isOpen={dropdown} toggle={handleDropdown}>
              <DropdownToggle
                className="btn btn-account account__btn account__btn--small"
                caret
                style={{ width: "100%" }}
              >
                Choice Of Payout Type
              </DropdownToggle>
              <DropdownMenu className="modal-dropdown" container="body">
                <DropdownItem onClick={handleMasterCard}>
                  Bank Payout
                </DropdownItem>
                <DropdownItem onClick={handlePayPal}>PayPal</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        )}
        {updateYourData && (
          <>
            <h4 style={{ margin: "20px 0px" }}>Update Your Payout</h4>
            {/* <p>you must have deposited payout method</p> */}
          </>
        )}
        <form className="form" onSubmit={onSubmit}>
          {children}
        </form>
      </div>
    </Modal>
  );
}

export default ModalMenagementPayment;
