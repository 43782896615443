import { userConstants } from "../constanta/userContant";

export default function forgetPassword(state = {}, action) {
  switch (action.type) {
    case userConstants.PASSWORD_REQUEST:
      return {
        error: {},
        user: action.user,
      };
    case userConstants.PASSWORD_SUCCESS:
      return {
        user: action.user,
        error: {},
      };
    case userConstants.PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
