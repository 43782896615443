export const AffiliateConstants = {
    AFFILIATE_REQUEST: 'AFFILIATE_LIST_REQUEST',
    AFFILIATE_SUCCESS: 'AFFILIATE_LIST_SUCCESS',
    AFFILIATE_FAILURE: 'AFFILIATE_LIST_FAILURE',

    AFFILIATE_DASHBOARD_REQUEST: 'AFFILIATE_DASHBOARD_REQUEST',
    AFFILIATE_DASHBOARD_SUCCESS: 'AFFILIATE_DASHBOARD_SUCCESS',
    AFFILIATE_DASHBOARD_FAILURE: 'AFFILIATE_DASHBOARD_FAILURE',

    PROJECT_REQUEST: 'AFFILIATE_PROJECT_REQUEST',
    PROJECT_SUCCESS: 'AFFILIATE_PROJECT_SUCCESS',
    PROJECT_FAILURE: 'AFFILIATE_PROJECT_FAILURE',

    PAYOUT_REQUEST: 'AFFILIATE_PAYOUT_REQUEST',
    PAYOUT_SUCCESS: 'AFFILIATE_PAYOUT_SUCCESS',
    PAYOUT_FAILURE: 'AFFILIATE_PAYOUT_FAILURE',
};