

import { camelCase } from "lodash";
import { ErrorResponse } from "./response";

const USER = "user";

export function deleteAuthToken() {
  localStorage.removeItem(USER);
}

export const CekHttpNetworkResponse = (response) => {
  const onJsonError = new ErrorResponse(response);
  if (!response.ok) {
    let isBathAuth = false;
    if (response.status === 401) {
      isBathAuth = true;
      deleteAuthToken();
      window.location.reload();
    }
    if ("json" in response) {
      return response
        .json()
        .catch(() => {
          throw onJsonError;
        })
        .then((errorJson) => {
          const errors = Object.keys(errorJson).reduce(
            (err, k) => ({ ...err, [camelCase(k)]: errorJson[k] }),
            {},
          );
          const yeet = () => {
            throw new ErrorResponse(response, errors);
          };
          if (isBathAuth) {
            const delayedYeet = () => new Promise(() => setTimeout(yeet, 4000));
            return delayedYeet();
          }
          return yeet();
        });
    }
    return onJsonError;
  }
  return response;
};