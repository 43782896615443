import { combineReducers, createStore, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import {
  sidebarReducer, themeReducer, rtlReducer, authentication, registration,
  partnerAffiliate, userProfile, partnerReseller, paymentMethod,
  forgetPassword, affiliateDashboardFromConstant,
} from '../../redux/reducers/index';

// const loggerMiddleware = createLogger();

const reducer = combineReducers({
  partnerAffiliate,
  userProfile,
  paymentMethod,
  forgetPassword,
  authentication,
  affiliateDashboardFromConstant,
  partnerReseller,
  registration,
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
});

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

export default store;
