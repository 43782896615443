

import React from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import { ProfileDetail } from "../components/ProfileDetail";
import { PaymentView } from "./PaymentView";

// eslint-disable-next-line react/prefer-stateless-function
class Payment extends React.Component {
  render() {
    const {
      user,
    } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <ProfileDetail user={user}>
            <PaymentView />
          </ProfileDetail>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.userProfile;
  return { user };
}

const Payments = connect(mapStateToProps)(Payment);
export default Payments;