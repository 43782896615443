

import React from "react";



function CardPayPal({ email, children }) {
  return (
    <div className="card-payment-template">
      <div className="card__front card__part-PayPal">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="card__payment-title">PayPal</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {children}
          </div>
        </div>
        <div className="card_numer">
          <span className="card__label">Email</span>
          <div className="card__info">{email}</div>
        </div>
      </div>
    </div>
  );
}

export default CardPayPal;
