

import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { Panel } from "../../../../shared/components";



// eslint-disable-next-line react/prefer-stateless-function
class MenuOverView extends React.Component {
  state = {
    copied: false,
  };

  handleModal = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    const { user } = this.props;
    const affiliates = user.affiliate_code === null ? "" : `https://blankontech.com/?affiliate=${user.affiliate_code}`;
    return (
      <Panel xs={12} md={12} lg={12} title="Profile Overview" header>
        <div className="profile__overview profile__overview__margin">
          <h5 className="profile__overview_text">Full Name</h5>
          <div className="profile__overview_name">
            <span>
              {user.first_name}&nbsp;{user.last_name}
            </span>
          </div>
        </div>
        <div className="profile__overview profile__overview__margin">
          <h5 className="profile__overview_text">Email</h5>
          <div className="profile__overview_name">
            <span>{user.email}</span>
          </div>
        </div>
        <div className="profile__overview profile__overview__margin">
          <h5 className="profile__overview_text">Affiliate</h5>
          <div className="profile__overview_name">
            <span style={{ marginRight: "12px" }}>
              {affiliates === "" ? "" : `https://blankontech.com/?affiliate=${user.affiliate_code}`}
            </span>
            <CopyToClipboard
              text={affiliates}
              onCopy
            >
              <FontAwesomeIcon style={{ cursor: "pointer" }} className="icon-copy" icon={faClipboard} id="copy" />
            </CopyToClipboard>
            <UncontrolledTooltip placement="right" target="copy">
              Copy Affiliate Link
            </UncontrolledTooltip>
          </div>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.userProfile;
  return { user };
}

const OverviewComponent = connect(mapStateToProps)(MenuOverView);
export { OverviewComponent as MenuOverView };
