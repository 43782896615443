import { userConstants } from "../constanta/userContant";

const initialState = {
    user: {},
    isLoading: false,
};

export default function userProfile(state = initialState, action) {
    switch (action.type) {
        case userConstants.UPDATE_REQUEST:
            return {
                ...state,
                user: state.user,
                isLoading: true,
            };
        case userConstants.UPDATE_SUCCESS:
            return {
                ...state,
                user: action.user,
                isLoading: false,
            };
        case userConstants.GET_REQUEST:
            return {
                ...state,
            };
        case userConstants.GET_SUCCESS:
            return {
                ...state,
                user: action.users,
            };
        case userConstants.GET_FAILURE:
            return {
                ...state,
                user: action.users,
            };
        default:
            return state;
    }
}