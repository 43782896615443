

import React from "react";

// type Props = {
//     children: Node,
// }

const flat = `${process.env.PUBLIC_URL}/img/flatCharacter.svg`;
const bubble = `${process.env.PUBLIC_URL}/img/bubble.svg`;

function LayoutAuth({ children }) {
  return (
    <div className="account">
      <div className="account__character ">
        <div className="account__character-ui">
          <h4 className="text__title">
            Build your connection for your idea and business
          </h4>
          <div className="account__character-title" />
          <img className="account__bubble" src={bubble} alt="" />
          <img className="account__character-flat" src={flat} alt="" />
        </div>
      </div>
      {children}
    </div>
  );
}

export { LayoutAuth };
