import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Switch>
      <Route
        {...rest}
        render={(props) => user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `${process.env.REACT_APP_BASE_URL}login`, state: { from: props.location } }}
          />
        )}
      />
    </Switch>
  );
};

export default PrivateRoute;
