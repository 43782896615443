

import React from "react";

// type Paypal = {
//   email,
// };

// type Props = {
//   submitted: boolean,
//   paypal: Paypal,
//   onChange: (paypal: Paypal) => void,
// };

function ModalFormPayPal({
  submitted,
  onChange: onInputChange,
  paypal,
}) {
  const onChange = (event) => {
    onInputChange({ paypal: { ...paypal, [event.target.name]: event.target.value } });
  };
  return (
    <div>
      <div className="form__form-group" style={{ marginTop: "0px" }}>
        <span className="form__form-group-label">Email</span>
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap">
            <input
              type="email"
              id="email"
              placeholder="Email"
              value={paypal.email}
              onChange={onChange}
              name="email"
            />
            {submitted && paypal.email === "" ? (
              <div
                id="validationServer03Feedback"
                className="form__form-group-error"
              >
                This field is required
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalFormPayPal;
