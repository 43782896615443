

import { userService } from "../utils/userUtils";
import { userConstants } from "../constanta/userContant";
import authFetch from "../helpers/authFetch";
import { authHeader } from "../helpers/authHeader";

function updateUser(first_name, last_name, email, affiliate_code) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: { first_name, last_name, affiliate_code, email },
  };
  return (dispatch) => {
    dispatch(request({ requestOptions }));
    return authFetch(`account/update/`, requestOptions)
      .then((user) => {
        dispatch(success(user));
      });
  };
  function request(user) {
    return { type: userConstants.UPDATE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPDATE_SUCCESS, user };
  }
}

function getUser() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return (dispatch) => {
    dispatch(request());
    return authFetch(`account/me/`, requestOptions)
      .then((user) => {
        dispatch(success(user));
      });
  };
}

function request() {
  return { type: userConstants.GET_REQUEST };
}
function success(users) {
  return { type: userConstants.GET_SUCCESS, users };
}

export const profileActions = {
  updateUser,
  getUser,
};
