

import { CircularProgress } from "@mui/material";
import React from "react";
import ModalMenagementPayment from "./ModalMenagementPayment";

// type Props = {
//   type,
//   toggle: boolean,
//   handleModal: () => void,
//   onSubmit: () => void,
//   handleMasterCard: () => void,
//   handlePayPal: () => void,
//   dropdown: boolean,
//   handleDropdown: () => void,
//   addPayment: boolean,
//   children: Node,
// };
// eslint-disable-next-line react/prefer-stateless-function
class ModalFormPayment extends React.Component {
  render() {
    const {
      handleDropdown,
      toggle,
      dropdown,
      type,
      addPayment,
      handleModal,
      children,
      handleMasterCard,
      handlePayPal,
      onSubmit,
      handleClearState,
    } = this.props;
    return (
      <>
        <ModalMenagementPayment
          dropdown={dropdown}
          handleMasterCard={handleMasterCard}
          handlePayPal={handlePayPal}
          handleModal={handleModal}
          type={type}
          toggle={toggle}
          handleDropdown={handleDropdown}
          onSubmit={onSubmit}
          addPayment={addPayment}
          handleClearState={handleClearState}
        >
          {children}
        </ModalMenagementPayment>
      </>
    );
  }
}

export default ModalFormPayment;
