export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    PASSWORD_REQUEST: 'USERS_PASSWORD_REQUEST',
    PASSWORD_SUCCESS: 'USERS_PASSWORD_SUCCESS',
    PASSWORD_FAILURE: 'USERS_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    GET_REQUEST: 'USERS_GET_REQUEST',
    GET_SUCCESS: 'USERS_GET_SUCCESS',
    GET_FAILURE: 'USERS_GET_FAILURE',

    ADD_ERRORS: 'ADD_ERRORS_MESSAGE',

    LOGOUT: 'USERS_LOGOUT',
};
