import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "reactstrap";

function ModalBtn({
  toggle,
  textBtn,
  children,
  openModal,
  icon,
  headerText,
  color,
  header,
}) {
  return (
    <>
      <Button className="icon" onClick={toggle} color={color}>
        {icon}
        {textBtn}
      </Button>
      <Modal
        centered
        isOpen={openModal}
        toggle={toggle}
        className={`modal-dialog--header modal-dialog--${color}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          {header}
          <h4 className="text-modal modal__title">{headerText}</h4>
        </div>
        {children}
      </Modal>
    </>
  );
}

// ModalBtn.propTypes = {
//   toggle: PropTypes.func,
//   textBtn: PropTypes.string,
//   children: PropTypes.node,
//   openModal: PropTypes.bool,
//   icon: PropTypes.node,
//   headerText: PropTypes.string,
//   color: PropTypes.string,
//   header: PropTypes.bool,
// };

ModalBtn.defaultProps = {
  toggle: () => () => { },
  textBtn: "",
  children: {},
  openModal: false,
  icon: {},
  headerText: "",
  color: "",
  header: false,
};

export default ModalBtn;
