import React from "react";
import { Modal, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faInfoCircle, faUniversity } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

// eslint-disable-next-line react/prefer-stateless-function
class ModalDetailPayment extends React.Component {
  render() {
    const {
      toggle,
      handleModal,
      bank_name,
      routing_number,
      iban,
      bic,
      type,
      account_number,
      email,
      usd,
      name,
    } = this.props;
    return (
      <Modal
        centered
        isOpen={toggle}
        toggle={handleModal}
        className="modal-dialog--header modal-dialog--payment"
        style={{ textAlign: "start" }}
      >
        <div className="modal__header">
          <h5 className="text-modal modal__title">
            {type === 2 && (
              <div className="modal__border-payment-paypal">
                <FontAwesomeIcon
                  className="card-payPal-modal"
                  icon={faPaypal}
                />
              </div>
            )}
            {type === 1 && (
              <div className="modal__border-payment-paypal">
                <FontAwesomeIcon
                  className="card-Bank-modal"
                  icon={faUniversity}
                />
              </div>
            )}
          </h5>
          <h5 className="text-modal modal__title" style={{ marginTop: "20px" }}>
            This is your payout detail
          </h5>
          <h5 className="text-modal modal__title" style={{ marginTop: "20px" }}>
            {name}
          </h5>
        </div>
        <div className="modal__body modal__body-payment">
          <div>
            {type === 1 && type !== "" ? (
              <h5 className="modal__detail-payment">
                Bank Payout: &nbsp;{bank_name}
              </h5>
            ) : (
              ""
            )}
          </div>
          <div className="">
            {type === 1 && type !== "" ? (
              <>
                {usd === "usd" ? (
                  <>
                    <div className="justify-number">
                      <h5 className="modal__detail-payment">
                        Account Number
                        <FontAwesomeIcon
                          style={{ marginLeft: "10px" }}
                          id="account_number"
                          icon={faInfoCircle}
                        />
                      </h5>
                      <div className="model__title-account-number">
                        {account_number}
                      </div>
                    </div>
                    <div className="justify-number">
                      <h5 className="modal__detail-payment">
                        Routing Number
                        <FontAwesomeIcon
                          style={{ marginLeft: "10px" }}
                          id="routing_number"
                          icon={faInfoCircle}
                        />
                      </h5>
                      <div className="model__title-account-number">
                        {routing_number}
                      </div>
                    </div>
                    <UncontrolledTooltip
                      placement="right"
                      target="account_number"
                    >
                      identifies the owner
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                      placement="right"
                      target="routing_number"
                    >
                      routing number identifies the location where your account
                      was opened
                    </UncontrolledTooltip>
                  </>
                ) : (
                  <>
                    <div className="justify-number">
                      <h5 className="modal__detail-payment">
                        BIC
                        <FontAwesomeIcon
                          style={{ marginLeft: "10px" }}
                          id="bic"
                          icon={faInfoCircle}
                        />
                      </h5>
                      <div className="model__title-account-number">{bic}</div>
                    </div>
                    <div className="justify-number">
                      <h5 className="modal__detail-payment">
                        IBAN
                        <FontAwesomeIcon
                          style={{ marginLeft: "10px" }}
                          id="iban"
                          icon={faInfoCircle}
                        />
                      </h5>
                      <div className="model__title-account-number">{iban}</div>
                    </div>
                    <UncontrolledTooltip placement="right" target="bic">
                      Bank Identifier Code
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="right" target="iban">
                      International Bank Account Number
                    </UncontrolledTooltip>
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            {type === 2 && type !== "" ? (
              <div className="">
                <div className="align-text">
                  <h4 className="modal__detail-payment">
                    Email
                    <FontAwesomeIcon
                      style={{ marginLeft: "10px" }}
                      id="email"
                      icon={faInfoCircle}
                    />
                  </h4>
                  <h5>{email}</h5>
                </div>
                <UncontrolledTooltip placement="right" target="email">
                  your paypal email account
                </UncontrolledTooltip>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

// ModalDetailPayment.propTypes = {
//   handleModal: PropTypes.func.isRequired,
//   toggle: PropTypes.bool.isRequired,
//   bank_name: PropTypes.string.isRequired,
//   routing_number: PropTypes.string.isRequired,
//   account_number: PropTypes.string.isRequired,
//   iban: PropTypes.string.isRequired,
//   bic: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
//   email: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   usd: PropTypes.string.isRequired,
// };

export default ModalDetailPayment;
